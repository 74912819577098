import React, { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useMemberGroupData } from 'v3/hooks/useMemberGroupData';

import SubmitClosing from 'v3/components/layout/SubmitClosing';

import { Button } from 'v3/components/ui/Button';

import { Close } from 'v3/components/svg/Close';

import { Card, StandardizedBumper } from 'v3/styles/shared';
import { CloseButtonWrapper, ModalBackground } from 'v3/styles/modal';

export const Modal = () => {
    const { group } = useMemberGroupData();

    const [searchParams, setSearchParams] = useSearchParams();

    const isModalOpen = searchParams.has('modalOpen');
    const modalContent = searchParams.get('modalContent');

    const onCloseModal = useCallback(() => {
        if (isModalOpen) {
            searchParams.delete('modalOpen');
            if (modalContent != null) {
                searchParams.delete('modalContent');
            }
            setSearchParams(searchParams);
        }
    }, [isModalOpen, modalContent, searchParams, setSearchParams]);

    const onClickModalInnerContent = useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            e.stopPropagation();
        },
        []
    );

    return isModalOpen ? (
        <ModalBackground onClick={onCloseModal}>
            <Card
                onClick={onClickModalInnerContent}
                $margin="auto"
                $width="720px"
            >
                {modalContent === 'topic-tool' ? (
                    <>
                        <CloseButtonWrapper>
                            <Button onClick={onCloseModal} $style="float">
                                <Close />
                            </Button>
                        </CloseButtonWrapper>
                        <StandardizedBumper />
                        <StandardizedBumper />
                    </>
                ) : null}
                {modalContent === 'closing' ? (
                    <SubmitClosing
                        sessionNumber={group?.session_counter ?? 0}
                        sessionDate={group?.next_session_date ?? ''}
                        isPreviousSessionClosing={false}
                        onClose={onCloseModal}
                    />
                ) : null}
            </Card>
        </ModalBackground>
    ) : null;
};
