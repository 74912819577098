import React, { useCallback } from 'react';

import { RATING_RESPONSES } from 'v3/libs/constants';

import { TextInput } from 'v3/components/ui/TextInput';
import { Select } from 'v3/components/ui/Select';

import { colors, Fonts } from 'v3/styles/theme';
import { PeriodicSurveyWrapper } from 'styles/periodicSurvey';
import { Divider, StandardizedBumper } from 'v3/styles/shared';

export interface PeriodicSurveyResponses {
    overall_rating?: string;
    overall_improvement_feedback?: string;
    coach_rating?: string;
    coach_improvement_feedback?: string;
    positive_feedback?: string;
    negative_feedback?: string;
}

interface PeriodicSurveyProps {
    periodicSurvey: PeriodicSurveyResponses;
    setPeriodicSurvey: (periodicSurvey: PeriodicSurveyResponses) => void;
}

export const PeriodicSurvey = ({
    periodicSurvey,
    setPeriodicSurvey,
}: PeriodicSurveyProps) => {
    const onOverallRatingChange = useCallback(
        (event: React.FormEvent<HTMLSelectElement>) => {
            const newPeriodicSurvey = { ...periodicSurvey };
            const target = event.target as HTMLSelectElement;
            newPeriodicSurvey.overall_rating = target.value;
            setPeriodicSurvey(newPeriodicSurvey);
        },
        [periodicSurvey, setPeriodicSurvey]
    );

    const onOverallFeedbackInput = useCallback(
        (event: React.FormEvent<HTMLTextAreaElement>) => {
            const newPeriodicSurvey = { ...periodicSurvey };
            const target = event.target as HTMLTextAreaElement;
            newPeriodicSurvey.overall_improvement_feedback = target.value;
            setPeriodicSurvey(newPeriodicSurvey);
        },
        [periodicSurvey, setPeriodicSurvey]
    );

    const onCoachRatingChange = useCallback(
        (event: React.FormEvent<HTMLSelectElement>) => {
            const newPeriodicSurvey = { ...periodicSurvey };
            const target = event.target as HTMLSelectElement;
            newPeriodicSurvey.coach_rating = target.value;
            setPeriodicSurvey(newPeriodicSurvey);
        },
        [periodicSurvey, setPeriodicSurvey]
    );

    const onCoachFeedbackInput = useCallback(
        (event: React.FormEvent<HTMLTextAreaElement>) => {
            const newPeriodicSurvey = { ...periodicSurvey };
            const target = event.target as HTMLTextAreaElement;
            newPeriodicSurvey.coach_improvement_feedback = target.value;
            setPeriodicSurvey(newPeriodicSurvey);
        },
        [periodicSurvey, setPeriodicSurvey]
    );

    const onPositiveFeedbackInput = useCallback(
        (event: React.FormEvent<HTMLTextAreaElement>) => {
            const newPeriodicSurvey = { ...periodicSurvey };
            const target = event.target as HTMLTextAreaElement;
            newPeriodicSurvey.positive_feedback = target.value;
            setPeriodicSurvey(newPeriodicSurvey);
        },
        [periodicSurvey, setPeriodicSurvey]
    );

    const onNegativeFeedbackInput = useCallback(
        (event: React.FormEvent<HTMLTextAreaElement>) => {
            const newPeriodicSurvey = { ...periodicSurvey };
            const target = event.target as HTMLTextAreaElement;
            newPeriodicSurvey.negative_feedback = target.value;
            setPeriodicSurvey(newPeriodicSurvey);
        },
        [periodicSurvey, setPeriodicSurvey]
    );

    return (
        <PeriodicSurveyWrapper>
            <Fonts.Heading7
                $color={colors.mountainAsh}
            >{`Periodic Member Survey`}</Fonts.Heading7>
            <div />
            <Fonts.Medium>{`Tell us about your overall coaching experience thus far, so we can learn and improve. Responses will be shared anonymously with your coach.`}</Fonts.Medium>
            <StandardizedBumper />
            <Divider />
            <StandardizedBumper />
            <Select
                onInput={onOverallRatingChange}
                value={periodicSurvey.overall_rating}
                label="What’s your overall rating for the group coaching experience?"
                required
            >
                <option value="" label="Select" hidden />
                {RATING_RESPONSES.map((overallRating) => (
                    <option key={overallRating} value={overallRating}>
                        {overallRating}
                    </option>
                ))}
            </Select>
            <StandardizedBumper />
            <StandardizedBumper />
            {RATING_RESPONSES.slice(2, 5).includes(
                periodicSurvey.overall_rating as (typeof RATING_RESPONSES)[number]
            ) ? (
                <>
                    <TextInput
                        id="overallFeedback"
                        name="overallFeedback"
                        value={
                            periodicSurvey.overall_improvement_feedback ?? ''
                        }
                        onInput={onOverallFeedbackInput}
                        label="What could improve this rating?"
                        isTextArea
                    />
                    <StandardizedBumper />
                    <StandardizedBumper />
                </>
            ) : null}
            <Select
                onInput={onCoachRatingChange}
                value={periodicSurvey.coach_rating}
                label="What’s your overall satisfaction rating with your coach?"
                required
            >
                <option value="" label="Select" hidden />
                {RATING_RESPONSES.map((coachRating) => (
                    <option key={coachRating} value={coachRating}>
                        {coachRating}
                    </option>
                ))}
            </Select>
            <StandardizedBumper />
            <StandardizedBumper />
            {RATING_RESPONSES.slice(2, 5).includes(
                periodicSurvey.coach_rating as (typeof RATING_RESPONSES)[number]
            ) ? (
                <>
                    <TextInput
                        id="coachFeedback"
                        name="coachFeedback"
                        value={periodicSurvey.coach_improvement_feedback ?? ''}
                        onInput={onCoachFeedbackInput}
                        label="Share feedback on what your coach should continue or improve."
                        isTextArea
                    />
                    <StandardizedBumper />
                    <StandardizedBumper />
                </>
            ) : null}
            <TextInput
                id="positiveFeedback"
                name="positiveFeedback"
                value={periodicSurvey.positive_feedback ?? ''}
                onInput={onPositiveFeedbackInput}
                label="Share specific feedback for what’s worked well overall."
                isTextArea
            />
            <StandardizedBumper />
            <StandardizedBumper />
            <TextInput
                id="negativeFeedback"
                name="negativeFeedback"
                value={periodicSurvey.negative_feedback ?? ''}
                onInput={onNegativeFeedbackInput}
                label="Share specific feedback for what could be improved overall."
                isTextArea
            />
        </PeriodicSurveyWrapper>
    );
};

export default PeriodicSurvey;
