import React, { useCallback, useRef, useState } from 'react';
import { Turnstile, TurnstileInstance } from '@marsidev/react-turnstile';

import { useToast } from 'hooks/useToast';

import BackgroundWrapper from 'components/BackgroundWrapper';
import RequiredAsterisk from 'components/RequiredAsterisk';
import TextInput from 'components/TextInput';
import LoadingButton from 'components/LoadingButton';

import GrandLogo from 'assets/grand-logo-green-vert.svg';

import { colors, Fonts, Spacing } from 'styles/theme';
import { ContentInnerWrapper } from 'styles/backgroundWrapper';
import { AuthForm, HeadingExplainer, Logo } from 'styles/auth';

function PasswordReset() {
    const { showErrorToast } = useToast();

    const [email, setEmail] = useState<string | null>(null);
    const [captchaToken, setCaptchaToken] = useState<string | null>(null);
    const [hasSubmittedRequest, setHasSubmittedRequest] =
        useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [isSubmittingRequest, setIsSubmittingRequest] =
        useState<boolean>(false);

    const turnstileRef = useRef<TurnstileInstance>();

    const onEmailInput = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            setEmail(target.value);

            if (isError) {
                setIsError(false);
            }
        },
        [isError]
    );

    const onSubmit = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            if (isSubmittingRequest) {
                return;
            }
            setIsSubmittingRequest(true);

            if (captchaToken != null) {
                const response = await fetch(
                    'https://us-central1-the-grand-app.cloudfunctions.net/gpreset_public',
                    {
                        method: 'POST',
                        body: JSON.stringify({
                            email: email,
                            token: captchaToken,
                        }),
                    }
                );

                if (response.status === 200) {
                    setHasSubmittedRequest(true);
                } else {
                    turnstileRef.current?.reset();
                    setIsError(true);
                    showErrorToast('Email not found');
                }
            }

            setIsSubmittingRequest(false);
        },
        [captchaToken, email, isSubmittingRequest, showErrorToast]
    );

    return (
        <BackgroundWrapper>
            <ContentInnerWrapper>
                <Logo src={GrandLogo} alt="grand-logo" />
                {!hasSubmittedRequest ? (
                    <>
                        <Fonts.Heading3>{`Reset password`}</Fonts.Heading3>
                        <HeadingExplainer>
                            {`Please enter your email to reset your password or get a new verification code`}
                            <RequiredAsterisk />
                        </HeadingExplainer>
                        <AuthForm onSubmit={onSubmit}>
                            <TextInput
                                type="email"
                                id="email"
                                name="email"
                                placeholder="Email address"
                                onInput={onEmailInput}
                                isError={isError}
                                required
                            />
                            <Spacing.Bumper />
                            <Turnstile
                                ref={turnstileRef}
                                siteKey="0x4AAAAAAAHdBQdhmUCPmC2X"
                                onSuccess={(token) => {
                                    setCaptchaToken(token);
                                }}
                                options={{
                                    theme: 'light',
                                }}
                                // Need to use inline styling as this is an external component
                                style={{
                                    boxShadow: colors.boxShadow,
                                }}
                            />
                            <Spacing.Bumper />
                            <LoadingButton
                                type="submit"
                                value="Submit"
                                isLoading={isSubmittingRequest}
                                $stretch
                            >
                                {`Submit`}
                            </LoadingButton>
                        </AuthForm>{' '}
                    </>
                ) : (
                    <>
                        <Fonts.Heading3>{`Instructions sent to your email`}</Fonts.Heading3>
                        <HeadingExplainer>
                            {`Please check your email for reset instructions. If you need additional support please email hi@thegrand.world`}
                        </HeadingExplainer>
                    </>
                )}
            </ContentInnerWrapper>
        </BackgroundWrapper>
    );
}

export default PasswordReset;
