import React, { useCallback, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { TurnstileInstance } from '@marsidev/react-turnstile';

import { useAuth } from 'v3/hooks/useAuth';
import { useToast } from 'v3/hooks/useToast';

import { TextInput } from 'v3/components/ui/TextInput';
import { TurnstileWrapper } from 'v3/components/ui/TurnstileWrapper';
import { Button } from 'v3/components/ui/Button';

import { GrandLogoVert } from 'v3/components/svg/GrandLogoVert';

import { colors, Fonts, transparencies } from 'v3/styles/theme';
import { StandardizedBumper } from 'v3/styles/shared';
import { AuthForm, AuthWrapper } from 'v3/styles/auth';

export const SignIn = () => {
    const { signInWithPassword } = useAuth();
    const { showErrorToast } = useToast();

    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [captchaToken, setCaptchaToken] = useState<string | null>(null);
    const [isError, setIsError] = useState<boolean>(false);
    const [isSigningIn, setIsSigningIn] = useState<boolean>(false);

    const turnstileRef = useRef<TurnstileInstance>();

    const onEmailInput = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            setEmail(target.value);

            if (isError) {
                setIsError(false);
            }
        },
        [isError]
    );

    const onPasswordInput = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            setPassword(target.value);

            if (isError) {
                setIsError(false);
            }
        },
        [isError]
    );

    const onSubmit = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            if (isSigningIn) {
                return;
            }
            setIsSigningIn(true);

            if (captchaToken == null) {
                showErrorToast('CAPTCHA verification is required to sign in');
                return;
            }

            const error = await signInWithPassword?.({
                email,
                password,
                captchaToken,
            });

            if (error != null) {
                turnstileRef.current?.reset();
                setIsError(true);
                showErrorToast(error.message);
            }

            setIsSigningIn(false);
        },
        [
            captchaToken,
            email,
            isSigningIn,
            password,
            showErrorToast,
            signInWithPassword,
        ]
    );

    return (
        <AuthWrapper>
            <Link to="/sign-in">
                <GrandLogoVert width={140} />
            </Link>
            <StandardizedBumper />
            <AuthForm onSubmit={onSubmit}>
                <Fonts.Heading6 $color={colors.mountainAsh}>
                    {`Sign in`}
                </Fonts.Heading6>
                <StandardizedBumper />
                <TextInput
                    type="email"
                    id="email"
                    name="email"
                    onInput={onEmailInput}
                    label="Email address"
                    isError={isError}
                    required
                    $backgroundColor={transparencies.darkJungle5}
                />
                <StandardizedBumper />
                <TextInput
                    type="password"
                    id="password"
                    name="password"
                    onInput={onPasswordInput}
                    label="Password"
                    isError={isError}
                    required
                    $backgroundColor={transparencies.darkJungle5}
                />
                <StandardizedBumper />
                <TurnstileWrapper
                    turnstileRef={turnstileRef}
                    onSuccess={setCaptchaToken}
                />
                <StandardizedBumper />
                <Button
                    type="submit"
                    value="Submit"
                    isLoading={isSigningIn}
                    $stretch
                >
                    {`Sign in`}
                </Button>
                <StandardizedBumper />
                <Link to="/v3/password-reset">
                    <Fonts.Small $isUnderlined>{`Reset password`}</Fonts.Small>
                </Link>
            </AuthForm>
        </AuthWrapper>
    );
};
