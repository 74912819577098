import React, {
    Fragment,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { Database } from 'v3/libs/supabaseTypes';
import {
    COMPANIES_FOUNDED_OPTIONS,
    EMPLOYEE_COUNT_OPTIONS,
    FUNDING_STAGE_OPTIONS,
    YEARS_EXPERIENCE_OPTIONS,
    COACHING_EXPERIENCE_OPTIONS,
    CONSUMER_SPONSOR_IDS,
} from 'v3/libs/constants';

import { supabase, useAuth } from 'v3/hooks/useAuth';
import { useToast } from 'v3/hooks/useToast';

import { FormStep, SteppedForm } from 'v3/components/layout/SteppedForm';
import { TextInput } from 'v3/components/ui/TextInput';
import { RequiredAsterisk } from 'v3/components/ui/RequiredAsterisk';
import { Select } from 'v3/components/ui/Select';

import { Fonts, spacing } from 'v3/styles/theme';
import {
    Checkbox,
    CheckboxWrapper,
    NestedCard,
    StandardizedBumper,
} from 'v3/styles/shared';

type SponsorData = Database['public']['Tables']['sponsors']['Row'];

type YearsExperienceOptions = (typeof YEARS_EXPERIENCE_OPTIONS)[number];
type FundingStageOptions = (typeof FUNDING_STAGE_OPTIONS)[number];
type EmployeeCountOptions = (typeof EMPLOYEE_COUNT_OPTIONS)[number];
type CompaniesFoundedOptions = (typeof COMPANIES_FOUNDED_OPTIONS)[number];
type CoachingExperienceOptions = (typeof COACHING_EXPERIENCE_OPTIONS)[number];

interface SubmitIntakeProps {
    onChangeFormStep: () => void;
    onSubmit: () => Promise<void>;
}

export const SubmitIntake = ({
    onChangeFormStep,
    onSubmit,
}: SubmitIntakeProps) => {
    const { userId, userProfile, userEmail } = useAuth();
    const { showSuccessToast, showErrorToast } = useToast();

    const parsedRawData = useMemo(
        () => JSON.parse(JSON.stringify(userProfile?.raw_data ?? {})),
        [userProfile?.raw_data]
    );

    // TODO: update all sponsor state to reference a SponsorData type from Supabase
    const [sponsor, setSponsor] = useState<SponsorData | null>(null);
    const [isSubmittingIntake, setIsSubmittingIntake] =
        useState<boolean>(false);

    // Form state for all applicant types
    const [linkedIn, setLinkedIn] = useState<string>('');
    const [country, setCountry] = useState<string>(
        parsedRawData?.country ?? ''
    );
    const [state, setState] = useState<string>(parsedRawData?.state ?? '');
    const [city, setCity] = useState<string>(parsedRawData?.city ?? '');
    const [title, setTitle] = useState<string>(parsedRawData?.headline ?? '');
    const [yearsExperience, setYearsExperience] =
        useState<YearsExperienceOptions | null>(null);
    const [applicationReason, setApplicationReason] = useState<string>('');
    const [imaginedFuture, setImaginedFuture] = useState<string>(
        userProfile?.imagined_future ?? ''
    );
    const [sponsorTimeSlots, setSponsorTimeSlots] = useState<object>({});
    const [availableTimeSlots, setAvailableTimeSlots] = useState<Array<string>>(
        []
    );
    const [otherSuggestedTime, setOtherSuggestedTime] = useState<string>('');
    const [other, setOther] = useState<string>('');

    // Founder-related form state
    const [company, setCompany] = useState<string>('');
    const [fundingStage, setFundingStage] =
        useState<FundingStageOptions | null>(null);
    const [employeeCount, setEmployeeCount] =
        useState<EmployeeCountOptions | null>(null);
    const [companiesFounded, setCompaniesFounded] =
        useState<CompaniesFoundedOptions | null>(null);

    // Consumer form state
    const [coachingExperiences, setCoachingExperiences] = useState<
        Array<CoachingExperienceOptions>
    >([]);

    const hasIntakeTimeslots = useMemo(
        () => Object.keys(sponsorTimeSlots).length > 0,
        [sponsorTimeSlots]
    );

    useEffect(() => {
        const getSponsor = async () => {
            const { data, error } = await supabase.from('sponsors').select('*');

            if (error == null) {
                setSponsor(data?.[0] ?? null);
                setSponsorTimeSlots(
                    JSON.parse(JSON.stringify(data?.[0]?.time_slots ?? {}))
                );
            } else {
                showErrorToast(error.message);
            }
        };

        if (userId !== '') {
            getSponsor();
        }
    }, [showErrorToast, userId]);

    const onLinkedInInput = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            setLinkedIn(target.value);
        },
        []
    );

    const onCountryInput = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            setCountry(target.value);
        },
        []
    );

    const onStateInput = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            setState(target.value);
        },
        []
    );

    const onCityInput = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            setCity(target.value);
        },
        []
    );

    const onTitleInput = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            setTitle(target.value);
        },
        []
    );

    const onCompanyInput = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            setCompany(target.value);
        },
        []
    );

    const onChangeYearsExperience = useCallback(
        (event: React.FormEvent<HTMLSelectElement>) => {
            const target = event.target as HTMLSelectElement;
            setYearsExperience(target.value as YearsExperienceOptions);
        },
        []
    );

    const onChangeFundingRound = useCallback(
        (event: React.FormEvent<HTMLSelectElement>) => {
            const target = event.target as HTMLSelectElement;
            setFundingStage(target.value as FundingStageOptions);
        },
        []
    );

    const onChangeEmployeeCount = useCallback(
        (event: React.FormEvent<HTMLSelectElement>) => {
            const target = event.target as HTMLSelectElement;
            setEmployeeCount(target.value as EmployeeCountOptions);
        },
        []
    );

    const onChangeCompaniesFounded = useCallback(
        (event: React.FormEvent<HTMLSelectElement>) => {
            const target = event.target as HTMLSelectElement;
            setCompaniesFounded(target.value as CompaniesFoundedOptions);
        },
        []
    );

    const onSelectCoachingExperiences = useCallback(
        (selectedExperience: CoachingExperienceOptions) => () => {
            if (coachingExperiences.includes(selectedExperience)) {
                setCoachingExperiences(
                    coachingExperiences.filter(
                        (value) => value !== selectedExperience
                    )
                );
            } else {
                setCoachingExperiences([
                    ...coachingExperiences,
                    selectedExperience,
                ]);
            }
        },
        [coachingExperiences]
    );

    const onApplicationReasonInput = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            setApplicationReason(target.value);
        },
        []
    );

    const onImaginedFutureInput = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            setImaginedFuture(target.value);
        },
        []
    );

    const onSelectAvailableTimeSlot = useCallback(
        (fullTimeSlotString: string) => () => {
            const newAvailableTimeSlots = [...availableTimeSlots];
            const availableTimeSlotIndex = availableTimeSlots.findIndex(
                (availableTimeSlot) => availableTimeSlot === fullTimeSlotString
            );
            if (availableTimeSlotIndex === -1) {
                newAvailableTimeSlots.push(fullTimeSlotString);
            } else {
                newAvailableTimeSlots.splice(availableTimeSlotIndex, 1);
            }
            setAvailableTimeSlots(newAvailableTimeSlots);
        },
        [availableTimeSlots]
    );

    const onOtherSuggestedTimeInput = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            setOtherSuggestedTime(target.value);
        },
        []
    );

    const onOtherInput = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            setOther(target.value);
        },
        []
    );

    const internalOnSubmit = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            if (isSubmittingIntake) {
                return;
            }
            setIsSubmittingIntake(true);

            const { error: profileError } = await supabase
                .from('profiles')
                .update({
                    linkedin: linkedIn,
                    imagined_future: imaginedFuture,
                    raw_data: {
                        ...(parsedRawData ?? {}),
                        headline: title,
                        country,
                        state,
                        city,
                    },
                })
                .eq('id', userId);

            if (profileError != null) {
                showErrorToast(profileError.message);
            }

            const availableTimeSlotsToUpdate = [...availableTimeSlots];
            if (otherSuggestedTime !== '') {
                availableTimeSlotsToUpdate.push(otherSuggestedTime);
            }

            const { error: intakeError } = await supabase
                .from('intakes')
                .insert({
                    created_by: userId,
                    email: userEmail,
                    years_experience: yearsExperience,
                    application_reason: applicationReason,
                    imagined_future: imaginedFuture,
                    available_time_slots: hasIntakeTimeslots
                        ? availableTimeSlotsToUpdate
                        : null,
                    other,
                    // Founder-specific fields
                    company: company === '' ? null : company,
                    funding_stage: fundingStage,
                    employee_count: employeeCount,
                    companies_founded: companiesFounded,
                    // Consumer fields
                    coaching_experiences: coachingExperiences,
                });

            if (intakeError == null) {
                showSuccessToast('Successfully submitted intake responses!');
                await onSubmit();
            } else {
                showErrorToast(intakeError.message);
            }

            setIsSubmittingIntake(false);
        },
        [
            isSubmittingIntake,
            linkedIn,
            imaginedFuture,
            parsedRawData,
            title,
            country,
            state,
            city,
            userId,
            availableTimeSlots,
            otherSuggestedTime,
            userEmail,
            yearsExperience,
            applicationReason,
            hasIntakeTimeslots,
            other,
            company,
            fundingStage,
            employeeCount,
            companiesFounded,
            coachingExperiences,
            showErrorToast,
            showSuccessToast,
            onSubmit,
        ]
    );

    const headerNode = useMemo(
        () => (
            <>
                <Fonts.Heading5>{`The Grand Application${
                    CONSUMER_SPONSOR_IDS.includes(sponsor?.id ?? '')
                        ? ''
                        : ` for ${sponsor?.sponsor_name}`
                }`}</Fonts.Heading5>
                <StandardizedBumper />
                <Fonts.Medium>
                    {`Complete your Grand application so that we can curate the best group for you.`}
                </Fonts.Medium>
                <StandardizedBumper />
            </>
        ),
        [sponsor?.id, sponsor?.sponsor_name]
    );

    const formSteps: Array<FormStep> = useMemo(
        () => [
            {
                isComplete:
                    country !== '' &&
                    state !== '' &&
                    city !== '' &&
                    ((sponsor?.type !== 'founder' &&
                        !CONSUMER_SPONSOR_IDS.includes(sponsor?.id ?? '')) ||
                        company !== '') &&
                    title !== '',
                formNode: (
                    <NestedCard>
                        <TextInput
                            type="text"
                            id="country"
                            name="country"
                            placeholder="eg. México"
                            value={country}
                            label="Country"
                            onInput={onCountryInput}
                            isError={false}
                            required
                        />
                        <StandardizedBumper />
                        <StandardizedBumper />
                        <TextInput
                            type="text"
                            id="state"
                            name="state"
                            placeholder="eg. Jalisco"
                            value={state}
                            label="State"
                            onInput={onStateInput}
                            isError={false}
                            required
                        />
                        <StandardizedBumper />
                        <StandardizedBumper />
                        <TextInput
                            type="text"
                            id="city"
                            name="city"
                            placeholder="eg. Guadalajara"
                            value={city}
                            label="City"
                            onInput={onCityInput}
                            isError={false}
                            required
                        />
                        <StandardizedBumper />
                        <StandardizedBumper />
                        {sponsor?.type === 'founder' ||
                        CONSUMER_SPONSOR_IDS.includes(sponsor?.id ?? '') ? (
                            <>
                                <TextInput
                                    type="text"
                                    id="company"
                                    name="company"
                                    value={company}
                                    label="Company"
                                    onInput={onCompanyInput}
                                    isError={false}
                                    required
                                />
                                <StandardizedBumper />
                                <StandardizedBumper />
                            </>
                        ) : null}
                        <TextInput
                            type="text"
                            id="title"
                            name="title"
                            value={title}
                            label="Title"
                            onInput={onTitleInput}
                            isError={false}
                            required
                        />
                        <StandardizedBumper />
                        <StandardizedBumper />
                        <TextInput
                            type="text"
                            id="linkedin"
                            name="linkedin"
                            value={linkedIn}
                            label="LinkedIn"
                            onInput={onLinkedInInput}
                            isError={false}
                        />
                    </NestedCard>
                ),
            },
            {
                isComplete:
                    yearsExperience != null &&
                    (sponsor?.type !== 'founder' ||
                        (fundingStage != null &&
                            employeeCount != null &&
                            companiesFounded != null)) &&
                    (!CONSUMER_SPONSOR_IDS.includes(sponsor?.id ?? '') ||
                        coachingExperiences.length > 0) &&
                    applicationReason !== '' &&
                    imaginedFuture !== '',
                formNode: (
                    <NestedCard>
                        <Select
                            onInput={onChangeYearsExperience}
                            value={yearsExperience ?? ''}
                            label="How many years of professional experience do you have?"
                            required
                        >
                            <option value="" label="Select" hidden />
                            {YEARS_EXPERIENCE_OPTIONS.map(
                                (yearsExperinceOption) => (
                                    <option
                                        key={yearsExperinceOption}
                                        value={yearsExperinceOption}
                                    >
                                        {yearsExperinceOption}
                                    </option>
                                )
                            )}
                        </Select>
                        <StandardizedBumper />
                        <StandardizedBumper />

                        {sponsor?.type === 'founder' ? (
                            <>
                                <Select
                                    onInput={onChangeFundingRound}
                                    value={fundingStage ?? ''}
                                    label="What stage of funding is your company at?`"
                                    required
                                >
                                    <option value="" label="Select" hidden />
                                    {FUNDING_STAGE_OPTIONS.map(
                                        (fundingStageOption) => (
                                            <option
                                                key={fundingStageOption}
                                                value={fundingStageOption}
                                            >
                                                {fundingStageOption}
                                            </option>
                                        )
                                    )}
                                </Select>
                                <StandardizedBumper />
                                <StandardizedBumper />
                                <Select
                                    onInput={onChangeEmployeeCount}
                                    value={employeeCount ?? ''}
                                    label="How many employees are there at your company?"
                                    required
                                >
                                    <option value="" label="Select" hidden />
                                    {EMPLOYEE_COUNT_OPTIONS.map(
                                        (employeeCountOption) => (
                                            <option
                                                key={employeeCountOption}
                                                value={employeeCountOption}
                                            >
                                                {employeeCountOption}
                                            </option>
                                        )
                                    )}
                                </Select>
                                <StandardizedBumper />
                                <StandardizedBumper />
                                <Select
                                    onInput={onChangeCompaniesFounded}
                                    value={companiesFounded ?? ''}
                                    label="How many revenue-generating companies have you founded prior to your current one?"
                                    required
                                >
                                    <option value="" label="Select" hidden />
                                    {COMPANIES_FOUNDED_OPTIONS.map(
                                        (companiesFoundedOption) => (
                                            <option
                                                key={companiesFoundedOption}
                                                value={companiesFoundedOption}
                                            >
                                                {companiesFoundedOption}
                                            </option>
                                        )
                                    )}
                                </Select>
                                <StandardizedBumper />
                                <StandardizedBumper />
                            </>
                        ) : null}

                        {CONSUMER_SPONSOR_IDS.includes(sponsor?.id ?? '') ? (
                            <>
                                <Fonts.Small>
                                    {`Which Grand Coaching experiences are you interested in?`}
                                    <RequiredAsterisk />
                                </Fonts.Small>
                                <StandardizedBumper
                                    $minHeight={spacing.small}
                                />
                                {COACHING_EXPERIENCE_OPTIONS.map(
                                    (coachingExperienceOption) => (
                                        <CheckboxWrapper
                                            key={coachingExperienceOption}
                                        >
                                            <Checkbox
                                                onChange={onSelectCoachingExperiences(
                                                    coachingExperienceOption
                                                )}
                                                value={coachingExperienceOption}
                                                type="checkbox"
                                                checked={coachingExperiences.includes(
                                                    coachingExperienceOption
                                                )}
                                            />
                                            <Fonts.Small>
                                                {coachingExperienceOption}
                                            </Fonts.Small>
                                        </CheckboxWrapper>
                                    )
                                )}
                                <StandardizedBumper />
                                <StandardizedBumper />
                            </>
                        ) : null}

                        <TextInput
                            id="application reason"
                            name="application reason"
                            value={applicationReason}
                            label="Tell us more about why you’d like to participate in The Grand’s group coaching."
                            subLabel="Write 2-3 sentences describing what you’re hoping to gain from this experience and why you’d be a good fit."
                            isTextArea
                            onInput={onApplicationReasonInput}
                            required
                        />
                        <StandardizedBumper />
                        <StandardizedBumper />
                        <TextInput
                            id="imagined future"
                            name="imagined future"
                            value={imaginedFuture}
                            label="Imagine it is 6 months from now. Where do you hope to be?"
                            isTextArea
                            onInput={onImaginedFutureInput}
                            required
                        />
                    </NestedCard>
                ),
            },
            {
                isComplete:
                    !hasIntakeTimeslots ||
                    Object.keys(availableTimeSlots).length > 0 ||
                    otherSuggestedTime !== '',
                formNode: (
                    <NestedCard>
                        {hasIntakeTimeslots ? (
                            <>
                                <Fonts.Small>
                                    {`Please select all of the time slots that work for you.`}
                                    <RequiredAsterisk />
                                </Fonts.Small>
                                <StandardizedBumper
                                    $minHeight={spacing.xSmall}
                                />
                                {Object.keys(sponsorTimeSlots).map(
                                    (timeFrameKey) => (
                                        <Fragment key={timeFrameKey}>
                                            <Fonts.Small $isOpenSansSemiBold>
                                                {timeFrameKey}
                                            </Fonts.Small>
                                            <StandardizedBumper
                                                $minHeight={spacing.xxSmall}
                                            />
                                            {/* TODO: add JSON typing once added to time_slots column in DB */}
                                            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                                            {/* @ts-ignore */}
                                            {sponsorTimeSlots[timeFrameKey].map(
                                                (timeSlot: string) => {
                                                    const fullTimeSlotString = `${timeFrameKey}, ${timeSlot}`;
                                                    return (
                                                        <CheckboxWrapper
                                                            key={timeSlot}
                                                        >
                                                            <Checkbox
                                                                onChange={onSelectAvailableTimeSlot(
                                                                    fullTimeSlotString
                                                                )}
                                                                value={timeSlot}
                                                                type="checkbox"
                                                                checked={availableTimeSlots.includes(
                                                                    fullTimeSlotString
                                                                )}
                                                            />
                                                            <Fonts.Small>
                                                                {timeSlot}
                                                            </Fonts.Small>
                                                        </CheckboxWrapper>
                                                    );
                                                }
                                            )}
                                            <StandardizedBumper
                                                $minHeight={spacing.small}
                                            />
                                        </Fragment>
                                    )
                                )}
                                <TextInput
                                    type="text"
                                    id="otherSuggestedTime"
                                    name="otherSuggestedTime"
                                    value={otherSuggestedTime}
                                    label="Is there another day and time slot available?"
                                    onInput={onOtherSuggestedTimeInput}
                                    isError={false}
                                />
                                <StandardizedBumper />
                                <StandardizedBumper />
                            </>
                        ) : null}

                        <TextInput
                            id="otherFeedback"
                            name="otherFeedback"
                            value={other}
                            label="Is there anything else you'd like for your coach to consider before your group starts?"
                            isTextArea
                            onInput={onOtherInput}
                        />
                    </NestedCard>
                ),
            },
        ],
        [
            country,
            state,
            city,
            sponsor?.type,
            sponsor?.id,
            company,
            title,
            onCountryInput,
            onStateInput,
            onCityInput,
            onCompanyInput,
            onTitleInput,
            linkedIn,
            onLinkedInInput,
            yearsExperience,
            fundingStage,
            employeeCount,
            companiesFounded,
            applicationReason,
            imaginedFuture,
            onChangeYearsExperience,
            onChangeFundingRound,
            onChangeEmployeeCount,
            onChangeCompaniesFounded,
            onApplicationReasonInput,
            onImaginedFutureInput,
            hasIntakeTimeslots,
            availableTimeSlots,
            otherSuggestedTime,
            sponsorTimeSlots,
            onOtherSuggestedTimeInput,
            other,
            onOtherInput,
            onSelectCoachingExperiences,
            coachingExperiences,
            onSelectAvailableTimeSlot,
        ]
    );

    return (
        <SteppedForm
            headerNode={headerNode}
            formSteps={formSteps}
            onChangeFormStep={onChangeFormStep}
            onFinalSubmit={internalOnSubmit}
        />
    );
};
