import React, { useEffect } from 'react';
import {
    Route,
    Routes,
    Navigate,
    matchRoutes,
    useLocation,
    useNavigate,
    useMatch,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useSupabase } from 'hooks/useSupabase';
import { useAnalytics } from 'hooks/useAnalytics';

import NavHeader from 'components/NavHeader';
import SignUp from 'components/SignUp';
import SignIn from 'components/SignIn';
import Confirm from 'components/Confirm';
import PasswordReset from 'components/PasswordReset';
import Sessions from 'components/Sessions';
import Closings from 'components/Closings';
import Retrospectives from 'components/Retrospectives';
import ImaginedFuture from 'components/ImaginedFuture';
import ProfilesContainer from 'components/ProfileContainer';
import Coach from 'components/Coach';
import OnboardingContainer from 'components/OnboardingContainer';
import GroupReports from 'components/GroupReports';
import RecapHistory from 'components/RecapHistory';
import DownTimeAlert from 'components/DowntimeAlert';
import MarkdownRouteHandler from 'components/MarkdownRouteHandler';

import { AppWithProviders as AppV3 } from 'v3/components/layout/App';

import { AppWrapper } from 'styles/appWrapper';

const { REACT_APP_NODE_ENV } = process.env;

const SITEWIDE_DOWNTIME = false;

export function UnAuthenticateRoute() {
    const navigate = useNavigate();
    const { hasInitialized, isSignedIn } = useSupabase();

    useEffect(() => {
        if (!hasInitialized) {
            return;
        }

        if (isSignedIn) {
            navigate('/');
        }
    }, [hasInitialized, isSignedIn, navigate]);

    return null;
}

export function AuthenticateRoute() {
    const navigate = useNavigate();
    const { hasInitialized, isSignedIn } = useSupabase();

    useEffect(() => {
        if (!hasInitialized) {
            return;
        }

        if (!isSignedIn) {
            navigate('/sign-in');
            return;
        }
    }, [hasInitialized, isSignedIn, navigate]);

    return null;
}

export function AuthenticateCouncilMemberRoute() {
    const navigate = useNavigate();
    const { hasInitialized, isSignedIn, userProfile } = useSupabase();

    useEffect(() => {
        if (!hasInitialized) {
            return;
        }

        if (!isSignedIn) {
            navigate('/sign-in');
        } else if (userProfile == null) {
            return;
        } else if (userProfile.sponsor_id != null) {
            navigate('/');
        } else if (userProfile.is_coach) {
            navigate('/coach');
        } else if ((userProfile.member_groups?.length ?? 0) === 0) {
            navigate('/onboarding');
        }
    }, [hasInitialized, isSignedIn, navigate, userProfile]);

    return null;
}

export function AuthenticateCoachRoute() {
    const navigate = useNavigate();
    const { hasInitialized, isSignedIn, userProfile } = useSupabase();

    useEffect(() => {
        if (!hasInitialized) {
            return;
        }

        if (!isSignedIn) {
            navigate('/sign-in');
        } else if (!userProfile?.is_coach) {
            navigate('/');
        }
    }, [hasInitialized, isSignedIn, navigate, userProfile?.is_coach]);

    return null;
}

export function AuthenticateSponsorRoute() {
    const navigate = useNavigate();
    const { hasInitialized, isSignedIn, userProfile } = useSupabase();

    useEffect(() => {
        if (!hasInitialized) {
            return;
        }

        if (!isSignedIn) {
            navigate('/sign-in');
        } else if (userProfile != null && userProfile?.sponsor_id == null) {
            navigate('/sessions');
        }
    }, [
        hasInitialized,
        isSignedIn,
        navigate,
        userProfile,
        userProfile?.sponsor_id,
    ]);

    return null;
}

export function AuthenticateOnboardingRoute() {
    const navigate = useNavigate();
    const { hasInitialized, isSignedIn, userProfile } = useSupabase();

    useEffect(() => {
        if (!hasInitialized) {
            return;
        }

        if (!isSignedIn) {
            navigate('/sign-in');
        } else if (userProfile == null) {
            return;
        } else if (userProfile.sponsor_id != null) {
            navigate('/');
        } else if (userProfile.is_coach) {
            navigate('/coach');
        } else if ((userProfile.member_groups?.length ?? 0) > 0) {
            navigate('/sessions');
        }
    }, [hasInitialized, isSignedIn, navigate, userProfile]);

    return null;
}

function AppRoutes() {
    // Run the useAnalytics at the highest level component to ensure page visits are captured
    useAnalytics();
    const { isSignedIn } = useSupabase();

    const location = useLocation();
    const isNavHeaderHiddenRoutes = matchRoutes(
        [
            { path: '/' },
            { path: '/confirm' },
            { path: '/password-reset' },
            { path: '/onboarding' },
            { path: '/preview-md/:markdownString?' },
        ],
        location
    );
    const v3Route = useMatch('/v3/*');

    return (
        <AppWrapper id="app-wrapper">
            {SITEWIDE_DOWNTIME ? (
                <DownTimeAlert />
            ) : (
                <>
                    {/* While these aren't routes themselves, we want to render them in the
                    highest level component in the app as possible */}
                    {isSignedIn &&
                    isNavHeaderHiddenRoutes == null &&
                    v3Route == null ? (
                        <NavHeader />
                    ) : null}
                    {v3Route == null ? <ToastContainer /> : null}

                    <Routes>
                        {/* Every time you add or remove a route, make sure to add or remove
                        AuthenticateComponent or Unauthenticate component to the rendered element
                        as needed */}

                        {/* Routes without any authentication handling */}
                        <Route path="/confirm" element={<Confirm />} />
                        <Route
                            path="/password-reset"
                            element={<PasswordReset />}
                        />

                        {/* Staging route for markdown use with Retool */}
                        {REACT_APP_NODE_ENV === 'development' ||
                        REACT_APP_NODE_ENV === 'staging' ? (
                            <Route
                                path="/preview-md/:markdownString?"
                                element={<MarkdownRouteHandler />}
                            />
                        ) : null}

                        {/* Routes accessible while user is not authenticated */}
                        <Route
                            path="/sign-up/:tempGroupSponsorId?/:sponsorName?"
                            element={<SignUp />}
                        />
                        <Route
                            path="/apply/:tempGroupSponsorId?/:sponsorName?"
                            element={<SignUp />}
                        />
                        <Route path="/sign-in" element={<SignIn />} />

                        {/* Routes accessible while sponsor is authenticated */}
                        <Route path="/" element={<GroupReports />} />

                        {/* Routes accessible while coach is authenticated */}
                        <Route path="/coach" element={<Coach />} />
                        <Route
                            path="/recap-history"
                            element={<RecapHistory />}
                        />

                        {/* Routes accessible while digital member is authenticated */}
                        <Route
                            path="/onboarding"
                            element={<OnboardingContainer />}
                        />

                        {/* Routes accessible while any user is authenticated */}
                        <Route
                            path="/profiles/:groupId/:profileId?"
                            element={<ProfilesContainer />}
                        />

                        {/* Routes accessible while Grand Council member is authenticated */}
                        <Route path="/sessions" element={<Sessions />} />
                        <Route path="/closings" element={<Closings />} />
                        <Route
                            path="/retrospectives"
                            element={<Retrospectives />}
                        />
                        <Route
                            path="/imagined-future"
                            element={<ImaginedFuture />}
                        />

                        {/* V3!!! */}
                        <Route path="v3/*" element={<AppV3 />} />

                        {/* If the user tries to go to a route that doesn't exist, navigate to home */}
                        <Route path="*" element={<Navigate to="/" replace />} />
                    </Routes>
                </>
            )}
        </AppWrapper>
    );
}

export default AppRoutes;
