import { ButtonHTMLAttributes, PropsWithChildren, useMemo } from 'react';

import React from 'react';

import { Loader } from 'v3/components/ui/Loader';

import { ChevronSmall } from 'v3/components/svg/ChevronSmall';

import {
    Button as StyledButton,
    ButtonProps as StyledButtonProps,
} from 'v3/styles/button';

interface ButtonProps
    extends PropsWithChildren<
        ButtonHTMLAttributes<HTMLButtonElement> & StyledButtonProps
    > {
    isBackButton?: boolean;
    isLoading?: boolean;
    isDisabled?: boolean;
}

export const Button = (props: ButtonProps) => {
    const { children, isBackButton, isLoading, isDisabled } = props;

    const childNode = useMemo(
        () => (isBackButton ? <ChevronSmall /> : children),
        [children, isBackButton]
    );

    return (
        <StyledButton
            {...props}
            $isBackButton={isBackButton ?? false}
            $isLoading={isLoading ?? false}
            $isDisabled={isDisabled ?? false}
            $style={isBackButton ? 'outline' : props.$style}
        >
            {!(isLoading ?? false) ? <>{childNode}</> : <Loader />}
        </StyledButton>
    );
};
