import styled, { css } from 'styled-components';

import {
    ColorsTransparencies,
    colors,
    spacing,
    transparencies,
} from 'v3/styles/theme';

export const TextInputWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export interface TextInputProps {
    $borderColor?: ColorsTransparencies;
    $backgroundColor?: ColorsTransparencies;
}

export const TextInput = styled.input<TextInputProps>`
    position: relative;
    outline: none;
    border: 1px solid ${transparencies.darkJungle5};
    border-radius: ${spacing.radiusSmall};
    background-color: ${colors.white};
    width: 100%;
    padding: ${spacing.xSmall};

    &::placeholder {
        color: ${colors.grey30};
    }

    ${(props) =>
        props.$borderColor == null
            ? null
            : css`
                  border-color: ${props.$borderColor};
              `}

    ${(props) =>
        props.$backgroundColor == null
            ? null
            : css`
                  background-color: ${props.$backgroundColor};
              `}
`;

export const TextArea = styled.textarea<TextInputProps>`
    position: relative;
    outline: none;
    border: 1px solid ${transparencies.darkJungle5};
    border-radius: ${spacing.radiusSmall};
    background-color: ${colors.white};
    width: 100%;
    padding: ${spacing.xSmall};

    &::placeholder {
        color: ${colors.grey30};
    }

    ${(props) =>
        props.$borderColor == null
            ? null
            : css`
                  border-color: ${props.$borderColor};
              `}

    ${(props) =>
        props.$backgroundColor == null
            ? null
            : css`
                  background-color: ${props.$backgroundColor};
              `}
`;
