import { QueryData } from '@supabase/supabase-js';
import { Database } from 'libs/supabaseTypes';

import { supabase } from 'hooks/useSupabase';

type ModuleData = Database['public']['Tables']['modules']['Row'];
export type ExerciseFrameworkData =
    Database['public']['Tables']['exercises_frameworks']['Row'];
export type ModuleJoinedData = ModuleData & {
    exercises_frameworks: Array<ExerciseFrameworkData>;
};
export type SessionDetailsModulesData =
    Database['public']['Tables']['session_details_modules']['Row'];

export const groupProgramHistoryQuery = supabase
    .from('group_program_history')
    .select(
        `*,
        group_program_history_session_details!inner(*),
        session_details(
            *,
            session_details_modules!left(*),
            modules(*,
                exercises_frameworks(*)
            )
        )
    `
    );

export type JoinedGroupProgramHistory = QueryData<
    typeof groupProgramHistoryQuery
>;

export const repeatableSessionDetailsQuery = supabase
    .from('session_details')
    .select(
        `*,
        session_details_modules!left(*),
        modules(*,
            exercises_frameworks(*)
        )
    `
    )
    .eq('is_repeatable', true);

export type JoinedRepeatableSessionDetails = QueryData<
    typeof repeatableSessionDetailsQuery
>;

export const sortSessionDetails = (
    groupProgramHistory: JoinedGroupProgramHistory | null
) =>
    groupProgramHistory == null
        ? []
        : structuredClone(
              groupProgramHistory?.[0]?.group_program_history_session_details ??
                  []
          )
              .filter(
                  // Type check is working in development, but compiler flags on build
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  (sessionDetailsJoinDatum) =>
                      !sessionDetailsJoinDatum?.is_banked
              )
              .sort(
                  // Type check is working in development, but compiler flags on build
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  (a, b) => (a?.session_number ?? 0) - (b?.session_number ?? 0)
              )
              // Type check is working in development, but compiler flags on build
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              .map((sessionDetailsJoinDatum) =>
                  groupProgramHistory?.[0]?.session_details?.find(
                      (sessionDetailsDatum) =>
                          sessionDetailsDatum?.id ===
                          sessionDetailsJoinDatum?.session_details_id
                  )
              );

export const sortModules = (
    sessionDetailsModulesData: Array<SessionDetailsModulesData>,
    modules: Array<ModuleJoinedData>
) =>
    sessionDetailsModulesData
        // Type check is working in development, but compiler flags on build
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .sort((a, b) => (a?.module_rank ?? 0) - (b?.module_rank ?? 0))
        // Type check is working in development, but compiler flags on build
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .map((moduleJoinDatum) =>
            modules.find(
                // Type check is working in development, but compiler flags on build
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                (module) => module?.id === moduleJoinDatum?.modules_id
            )
        )
        // Type check is working in development, but compiler flags on build
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .filter((module) => module != null) ?? [];

export const filterBankedSessionDetails = (
    groupProgramHistory: JoinedGroupProgramHistory | null
) =>
    groupProgramHistory == null
        ? []
        : structuredClone(
              groupProgramHistory?.[0]?.group_program_history_session_details ??
                  []
          )
              .filter(
                  // Type check is working in development, but compiler flags on build
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  (sessionDetailsJoinDatum) =>
                      sessionDetailsJoinDatum?.is_banked
              )
              // Type check is working in development, but compiler flags on build
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              .map((sessionDetailsJoinDatum) =>
                  groupProgramHistory?.[0]?.session_details?.find(
                      (sessionDetailsDatum) =>
                          sessionDetailsDatum?.id ===
                          sessionDetailsJoinDatum?.session_details_id
                  )
              );
