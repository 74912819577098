import styled from 'styled-components';

import {
    colors,
    FontColorProps,
    setFontColor,
    spacing,
    transparencies,
} from 'v3/styles/theme';

export const SelectWrapper = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const Select = styled.select<FontColorProps>`
    outline: none;
    border: 1px solid ${transparencies.darkJungle5};
    border-radius: ${spacing.radiusSmall};
    background-color: ${colors.white};
    width: 100%;
    padding: ${spacing.xSmall} ${spacing.xSmall};
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
    ${setFontColor}

    &:focus {
        border-color: ${colors.focus};
    }
`;

export const DropdownArrowWrapper = styled.div`
    position: absolute;
    // Custom absolute positioning for symmetry
    bottom: 9px;
    right: ${spacing.small};
    pointer-events: none;
    transform: rotate(90deg);
`;
