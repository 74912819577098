import { FC, SVGProps } from 'react';

import styled, { createGlobalStyle, css } from 'styled-components';
import { normalize } from 'styled-normalize';

import GelicaRegular from 'v3/fonts/Gelica/Gelica-Regular.otf';
import GelicaLight from 'v3/fonts/Gelica/Gelica-Light.otf';
import GelicaLightItalic from 'v3/fonts/Gelica/Gelica-LightItalic.otf';
import OpenSansRegular from 'fonts/OpenSans/OpenSans-Regular.ttf';
import OpenSansSemiBold from 'fonts/OpenSans/OpenSans-SemiBold.ttf';

// TODO: do not use this legacy medium screen size handling once
// reponsive styling is being implemented in V3
export const MEDIUM_BREAKPOINT = 1032;

// TODO: implement a more robust way of handling this since
// this method will not take into account window resizing
export const isMediumScreenSize = window.innerWidth <= MEDIUM_BREAKPOINT;

export const spacing = {
    xxxSmall: '4px',
    xxSmall: '8px',
    xSmall: '12px',
    small: '16px',
    medium: '20px',
    large: '24px',
    xLarge: '32px',
    xxLarge: '40px',
    xxxLarge: '48px',
    radiusXSmall: '4px',
    radiusSmall: '8px',
    radiusMedium: '12px',
    radiusLarge: '16px',
    mobileBreakpoint: '520px',
} as const;

export const mobileCssQuerist = (cssString: ReturnType<typeof css>) => css`
    @media only screen and (max-width: ${spacing.mobileBreakpoint}) {
        ${cssString}
    }
`;

export const colors = {
    // Brand
    grandGreen: '#264C51',
    grandGold: '#E0A246',
    sunsetOrange: '#D5571A',

    // Dark
    darkJungle: '#08262A',
    mountainAsh: '#C77700',

    // Shades of grey
    white: '#FFFFFF',
    light: '#F5F3F0',
    grey10: '#D5DBDB',
    grey20: '#ACB6B8',
    grey30: '#748588',
    grey40: '#395155',
    black: '#000000',

    // Alerts
    error: '#D10000',
    warning: '#EBD72A',
    success: '#3F9E4E',
    focus: '#1577D1',
} as const;

const transparencyHexCodes = {
    5: '0D',
    8: '14',
    10: '1A',
    12: '1F',
    16: '29',
    20: '33',
    25: '40',
    32: '52',
    40: '66',
    60: '99',
    80: 'CC',
} as const;

export const transparencies = {
    darkJungle5: `${colors.darkJungle}${transparencyHexCodes[5]}`,
    darkJungle10: `${colors.darkJungle}${transparencyHexCodes[10]}`,
    darkJungle20: `${colors.darkJungle}${transparencyHexCodes[20]}`,
    darkJungle40: `${colors.darkJungle}${transparencyHexCodes[40]}`,
    darkJungle60: `${colors.darkJungle}${transparencyHexCodes[60]}`,
    darkJungle80: `${colors.darkJungle}${transparencyHexCodes[80]}`,

    mountainAsh10: `${colors.mountainAsh}${transparencyHexCodes[10]}`,
    mountainAsh20: `${colors.mountainAsh}${transparencyHexCodes[20]}`,
    mountainAsh40: `${colors.mountainAsh}${transparencyHexCodes[40]}`,
    mountainAsh60: `${colors.mountainAsh}${transparencyHexCodes[60]}`,
    mountainAsh80: `${colors.mountainAsh}${transparencyHexCodes[80]}`,

    success10: `${colors.success}${transparencyHexCodes[10]}`,
    success20: `${colors.success}${transparencyHexCodes[20]}`,
    success40: `${colors.success}${transparencyHexCodes[40]}`,
    success60: `${colors.success}${transparencyHexCodes[60]}`,
    success80: `${colors.success}${transparencyHexCodes[80]}`,

    white10: `${colors.white}${transparencyHexCodes[10]}`,
    white20: `${colors.white}${transparencyHexCodes[20]}`,
    white40: `${colors.white}${transparencyHexCodes[40]}`,
    white60: `${colors.white}${transparencyHexCodes[60]}`,
    white80: `${colors.white}${transparencyHexCodes[80]}`,
} as const;

export const shadows = {
    smallBase: `0 3px 6px -3px ${colors.darkJungle}${transparencyHexCodes[16]}`,
    smallTop: `0 0 1px 0 ${colors.darkJungle}${transparencyHexCodes[32]}`,
    get smallBoxShadow() {
        return `${this.smallBase}, ${this.smallTop}`;
    },
    mediumBase: `0 12px 12px -6px ${colors.darkJungle}${transparencyHexCodes[16]}`,
    mediumTop: `0 0 1px 0 ${colors.darkJungle}${transparencyHexCodes[40]}`,
    get mediumBoxShadow() {
        return `${this.mediumBase}, ${this.mediumTop}`;
    },
    largeBase: `0 8px 24px -6px ${colors.darkJungle}${transparencyHexCodes[16]}`,
    largeTop: `0 0 1px 0 ${colors.darkJungle}${transparencyHexCodes[40]}`,
    get largeBoxShadow() {
        return `${this.largeBase}, ${this.largeTop}`;
    },
    xLargeBase: `0 32px 32px -8px ${colors.darkJungle}${transparencyHexCodes[8]}`,
    xLargeMiddle: `0 0 32px -8px ${colors.darkJungle}${transparencyHexCodes[12]}`,
    xLargeTop: `0 0 1px 0 ${colors.darkJungle}${transparencyHexCodes[20]}`,
    get xLargeBoxShadow() {
        return `${this.xLargeBase}, ${this.xLargeMiddle}, ${this.xLargeTop}`;
    },
    buttonBoxShadow: `0 4px 4px 0 ${colors.black}${transparencyHexCodes[25]}`,
} as const;

type SpacingKeys = keyof typeof spacing;
export type Spacing = (typeof spacing)[SpacingKeys];

type ColorKeys = keyof typeof colors;
type ColorValues = (typeof colors)[ColorKeys];
type TransparencyKeys = keyof typeof transparencies;
type TransparencyValues = (typeof transparencies)[TransparencyKeys];
export type ColorsTransparencies = ColorValues | TransparencyValues;

export type CustomSVGProps = SVGGeneralColorProps & SVGProps<SVGSVGElement>;

export type SVGComponent = FC<CustomSVGProps>;

export interface FontColorProps {
    $color?: ColorsTransparencies;
}

interface TextAlignProps {
    $textAlign?: 'center' | 'left' | 'right';
}

type MainFontProps = FontColorProps & TextAlignProps;

interface OpenSansFontWeightProps {
    $isOpenSansSemiBold?: boolean;
}

interface GelicaFontWeightProps {
    $isGelicaRegular?: boolean;
}

interface UnderlineProps {
    $isUnderlined?: boolean;
}

interface CaptializationProps {
    $isCapitalized?: boolean;
}

export const setFontColor = (props: FontColorProps) =>
    css`
        color: ${props.$color == null ? colors.darkJungle : props.$color};
    `;

const setTextAlign = (props: TextAlignProps) =>
    css`
        text-align: ${props.$textAlign == null ? 'inherit' : props.$textAlign};
    `;

const setOpenSansFontWeight = (props: OpenSansFontWeightProps) =>
    props.$isOpenSansSemiBold ?? false
        ? css`
              font-family: 'OpenSans-SemiBold';
          `
        : null;

const setGelicaFontWeight = (props: GelicaFontWeightProps) =>
    props.$isGelicaRegular ?? false
        ? css`
              font-family: 'Gelica-Regular';
          `
        : null;

const setUnderline = (props: UnderlineProps) =>
    props.$isUnderlined ?? false
        ? css`
              text-decoration: underline;
          `
        : null;

const setCapitalization = (props: CaptializationProps) =>
    props.$isCapitalized ?? false
        ? css`
              text-transform: uppercase;
              letter-spacing: 1px;
          `
        : null;

export const Fonts = {
    XxxSmall: styled.span<
        MainFontProps & OpenSansFontWeightProps & CaptializationProps
    >`
        font-family: 'OpenSans-Regular';
        font-size: 9px;
        line-height: 16px;
        ${setFontColor}
        ${setTextAlign}
        ${setCapitalization}
        ${setOpenSansFontWeight}
    `,
    XxSmall: styled.span<
        MainFontProps &
            OpenSansFontWeightProps &
            CaptializationProps &
            UnderlineProps
    >`
        font-family: 'OpenSans-Regular';
        font-size: 10px;
        line-height: 16px;
        ${setFontColor}
        ${setTextAlign}
        ${setOpenSansFontWeight}
        ${setUnderline}
        ${setCapitalization}
    `,
    XSmall: styled.span<
        MainFontProps &
            OpenSansFontWeightProps &
            CaptializationProps &
            UnderlineProps
    >`
        font-family: 'OpenSans-Regular';
        font-size: 12px;
        line-height: 16px;
        ${setFontColor}
        ${setTextAlign}
        ${setOpenSansFontWeight}
        ${setUnderline}
        ${setCapitalization}
    `,
    Small: styled.span<
        MainFontProps & OpenSansFontWeightProps & UnderlineProps
    >`
        font-family: 'OpenSans-Regular';
        font-size: 14px;
        line-height: 18px;
        ${setFontColor}
        ${setTextAlign}
        ${setOpenSansFontWeight}
        ${setUnderline}
    `,
    Medium: styled.span<
        MainFontProps & OpenSansFontWeightProps & UnderlineProps
    >`
        font-family: 'OpenSans-Regular';
        font-size: 16px;
        line-height: 24px;
        ${setFontColor}
        ${setTextAlign}
        ${setOpenSansFontWeight}
        ${setUnderline}
    `,
    MediumQuote: styled.span<MainFontProps>`
        font-family: 'Gelica-LightItalic';
        font-size: 16px;
        line-height: 24px;
        ${setFontColor}
        ${setTextAlign}
    `,
    Large: styled.span<MainFontProps & OpenSansFontWeightProps>`
        font-family: 'OpenSans-Regular';
        font-size: 18px;
        line-height: 26px;
        ${setFontColor}
        ${setTextAlign}
        ${setOpenSansFontWeight}
    `,
    LargeQuote: styled.span<MainFontProps>`
        font-family: 'Gelica-LightItalic';
        font-size: 18px;
        line-height: 26px;
        ${setFontColor}
        ${setTextAlign}
    `,
    XLarge: styled.span<MainFontProps & OpenSansFontWeightProps>`
        font-family: 'OpenSans-Regular';
        font-size: 20px;
        line-height: 30px;
        ${setFontColor}
        ${setTextAlign}
        ${setOpenSansFontWeight}
    `,

    // The headings are inverted large fonts over 2x from the design system
    // 2xL = Heading7 through 8xL = Heading1
    Heading7: styled.span<MainFontProps & GelicaFontWeightProps>`
        font-family: 'Gelica-Light';
        font-size: 24px;
        line-height: 32px;
        ${setFontColor}
        ${setTextAlign}
        ${setGelicaFontWeight}
    `,
    Heading6: styled.span<MainFontProps & GelicaFontWeightProps>`
        font-family: 'Gelica-Light';
        font-size: 32px;
        line-height: 40px;
        ${setFontColor}
        ${setTextAlign}
        ${setGelicaFontWeight}
    `,
    Heading5: styled.span<MainFontProps & GelicaFontWeightProps>`
        font-family: 'Gelica-Light';
        font-size: 40px;
        line-height: 48px;
        ${setFontColor}
        ${setTextAlign}
        ${setGelicaFontWeight}
    `,
    Heading4: styled.span<MainFontProps & GelicaFontWeightProps>`
        font-family: 'Gelica-Light';
        font-size: 48px;
        line-height: 56px;
        ${setFontColor}
        ${setTextAlign}
        ${setGelicaFontWeight}
    `,
    Heading3: styled.span<MainFontProps & GelicaFontWeightProps>`
        font-family: 'Gelica-Light';
        font-size: 56px;
        line-height: 64px;
        ${setFontColor}
        ${setTextAlign}
        ${setGelicaFontWeight}
    `,
    Heading2: styled.span<MainFontProps & GelicaFontWeightProps>`
        font-family: 'Gelica-Light';
        font-size: 54px;
        line-height: 72px;
        ${setFontColor}
        ${setTextAlign}
        ${setGelicaFontWeight}
    `,
    Heading1: styled.span<MainFontProps & GelicaFontWeightProps>`
        font-family: 'Gelica-Light';
        font-size: 72px;
        line-height: 76px;
        ${setFontColor}
        ${setTextAlign}
        ${setGelicaFontWeight}
    `,
} as const;

export interface SVGGeneralColorProps {
    color?: ColorsTransparencies;
}

export const setSVGColor = ({ color }: SVGGeneralColorProps) =>
    color == null ? colors.darkJungle : color;

export const GlobalStyles = createGlobalStyle`
    ${normalize}

    /* The Grand's font face definitions */
    @font-face {
        font-family: 'Gelica-Regular';
        src: local('Gelica-Regular'),
            url(${GelicaRegular}) format('opentype');
        font-weight: normal;
    }

    @font-face {
        font-family: 'Gelica-Light';
        src: local('Gelica-Light'),
            url(${GelicaLight}) format('opentype');
        font-weight: 300;
    }

    @font-face {
        font-family: 'Gelica-LightItalic';
        src: local('Gelica-LightItalic'),
            url(${GelicaLightItalic}) format('opentype');
        font-weight: 300;
        font-style: italic;
    }

    @font-face {
        font-family: 'OpenSans-Regular';
        src: local('OpenSans-Regular'),
            url(${OpenSansRegular}) format('truetype');
        font-weight: regular;
    }

    @font-face {
        font-family: 'OpenSans-SemiBold';
        src: local('OpenSans-SemiBold'),
            url(${OpenSansSemiBold}) format('truetype');
        font-weight: 600;
    }

    /* Custom global styles */
    * {
        box-sizing: border-box;
        font-family: 'OpenSans-Regular';
        font-size: 16px;
        line-height: 24px;
    }

    button {
        outline: 0;
        border: 0;
        background-color: transparent;
        cursor: pointer;
    }

    /* Removing the underline from react-router-dom Link components */
    a {
        text-decoration: none;
    }
`;

export const ReactMarkdownOverridingStyles = createGlobalStyle`
    .markdownWrapper {
        padding-bottom: ${spacing.xxSmall};
        font-size: 16px;
        line-height: 24px;
    }

    .markdownWrapper h1 {
        margin-bottom: ${spacing.large};
        font-family: 'Gelica-Light';
        font-size: 24px;
        line-height: 32px;
    }

    .markdownWrapper h2 {
        margin-bottom: ${spacing.large};
        font-family: 'Gelica-Light';
        font-size: 24px;
        line-height: 32px;
        color: ${colors.mountainAsh};
    }

    .markdownWrapper h3 {
        margin-bottom: ${spacing.large};
        font-size: 20px;
        line-height: 30px;
    }

    .markdownWrapper p {
        margin-bottom: ${spacing.medium};
        color: ${colors.grandGreen};
    }

    .markdownWrapper em {
        font-style: italic;
        color: ${colors.grandGreen};
    }

    .markdownWrapper strong {
        font-family: 'OpenSans-SemiBold';
        color: ${colors.darkJungle};
    }

    .markdownWrapper blockquote {
        margin: 0;
        border-radius: ${spacing.radiusSmall};
        background-color: ${transparencies.darkJungle5};
        padding: ${spacing.xxxSmall} ${spacing.large} ${spacing.xxxSmall};
    }

    .markdownWrapper blockquote em {
        font-family: 'Gelica-LightItalic';
        color: ${colors.grandGreen};
    }

    .markdownWrapper ul {
        margin: ${spacing.xSmall} 0 0 ${spacing.xxxSmall};
        list-style: outside;
    
        &:last-of-type {
            margin-bottom: ${spacing.large};
        }
    }

    .markdownWrapper li {
        margin-top: ${spacing.xxSmall};
        padding-left: ${spacing.xxxSmall};
        color: ${colors.grandGreen};
    }
`;

export interface MarkdownWrapperStyledComponentProps {
    $shouldReduceTopMargin?: boolean;
}

export const MarkdownWrapperStyledComponent = styled.div<MarkdownWrapperStyledComponentProps>`
    margin-top: -${spacing.small};

    ${(props) => {
        switch (props.$shouldReduceTopMargin) {
            case true: {
                return css`
                    margin-top: -${spacing.xLarge};
                `;
            }
            case false:
            default: {
                return;
            }
        }
    }};
`;
