import React, { useCallback } from 'react';
import { Link, useMatch } from 'react-router-dom';

import { useAuth } from 'v3/hooks/useAuth';

import { MenuItem } from 'v3/components/ui/MenuItem';
import { Avatar } from 'v3/components/ui/Avatar';

import { GrandLogoHorz } from 'v3/components/svg/GrandLogoHorz';
import { Home } from 'v3/components/svg/Home';
import { Star } from 'v3/components/svg/Star';
import { Idea } from 'v3/components/svg/Idea';

import { colors, Fonts, spacing } from 'v3/styles/theme';
import { Card, Divider, StandardizedBumper } from 'v3/styles/shared';
import { LogOutButton, ProfileActionsWrapper } from 'v3/styles/navMenu';

export const NavMenu = () => {
    const { signOut, userProfile } = useAuth();
    const scheduledSessionRoute = useMatch('/v3/member/scheduled-session/*');
    const allSessionsRoute = useMatch('/v3/member/all-sessions/*');

    const onSignOut = useCallback(async () => {
        await signOut?.();
    }, [signOut]);

    return (
        <div>
            <StandardizedBumper $minHeight={spacing.xxxLarge} />
            <StandardizedBumper $minHeight={spacing.xxxLarge} />
            <Card
                $margin={`0 ${spacing.xxxLarge} 0 0`}
                $width="236px"
                $padding={spacing.large}
            >
                <GrandLogoHorz width={100} />
                <Divider />
                <Link to="/v3/member/scheduled-session/description">
                    <MenuItem
                        SVGComponent={Home}
                        text="Today's Session"
                        isSelected={scheduledSessionRoute != null}
                    />
                </Link>
                <Link to="/v3/member/all-sessions">
                    <MenuItem
                        SVGComponent={Star}
                        text="All Sessions"
                        isSelected={allSessionsRoute != null}
                    />
                </Link>
                <MenuItem
                    SVGComponent={Idea}
                    text="Insights"
                    isSelected={false}
                />
                <Divider />

                <ProfileActionsWrapper>
                    <Avatar
                        profileInitials={`${
                            userProfile?.full_name?.split(' ')?.[0]?.[0] ?? ''
                        }${userProfile?.full_name?.split(' ')?.[1]?.[0] ?? ''}`}
                    />
                    <LogOutButton onClick={onSignOut}>
                        <Fonts.Small
                            $color={colors.grey40}
                            $isUnderlined
                        >{`Log Out`}</Fonts.Small>
                    </LogOutButton>
                </ProfileActionsWrapper>
            </Card>
        </div>
    );
};
