import React, { useMemo } from 'react';

import { RelationshipToToday } from 'v3/libs/utils';

import { CheckRounded } from 'v3/components/svg/CheckRounded';
import { Lock } from 'v3/components/svg/Lock';
import { Unlock } from 'v3/components/svg/Unlock';

import { colors, Fonts } from 'v3/styles/theme';
import {
    ChronologyDataWrapper,
    SessionCardWrapper,
    TitleWrapper,
} from 'v3/styles/sessionCard';

interface SessionCardProps {
    number: number;
    sessionRelationshipToToday: RelationshipToToday;
    title: string;
    onClick: () => void;
}

export const SessionCard = ({
    number,
    title,
    sessionRelationshipToToday,
    onClick,
}: SessionCardProps) => {
    const svgToRender = useMemo(() => {
        switch (sessionRelationshipToToday) {
            case 'previous': {
                return <CheckRounded color={colors.success} />;
            }
            case 'today': {
                return <Unlock color={colors.success} />;
            }
            case 'upcoming':
            default: {
                return <Lock color={colors.grey30} />;
            }
        }
    }, [sessionRelationshipToToday]);

    return (
        <SessionCardWrapper
            onClick={onClick}
            $isUpcoming={sessionRelationshipToToday === 'upcoming'}
        >
            <ChronologyDataWrapper>
                <Fonts.Small
                    $color={colors.grandGreen}
                    $isOpenSansSemiBold
                >{`Session ${number}`}</Fonts.Small>
                {svgToRender}
            </ChronologyDataWrapper>
            <TitleWrapper
                $isUpcoming={sessionRelationshipToToday === 'upcoming'}
            >
                <Fonts.Large $color={colors.grandGreen}>{title}</Fonts.Large>
            </TitleWrapper>
        </SessionCardWrapper>
    );
};
