import styled from 'styled-components';

import { colors, spacing, transparencies } from 'v3/styles/theme';

export const BackButtonWrapper = styled.div`
    position: absolute;
    left: ${spacing.large};
    top: ${spacing.large};
`;

export const ProgressBarWrapper = styled.div`
    margin: ${spacing.xxxSmall} ${spacing.xxxLarge} 0 ${spacing.xxLarge};
    border-radius: ${spacing.radiusXSmall};
    background-color: ${transparencies.darkJungle40};
    height: ${spacing.xxxSmall};
    display: flex;
    align-items: center;
`;

export const ProgressBar = styled.div`
    border-radius: ${spacing.radiusXSmall};
    background-color: ${colors.grandGold};
    height: 100%;
    transition: width 0.4s;
`;
