import styled from 'styled-components';

import { colors, spacing, transparencies } from 'v3/styles/theme';

export const CollapseButton = styled.div`
    border: 1px solid ${transparencies.darkJungle5};
    background-color: ${colors.white};
    width: ${spacing.large};
    height: ${spacing.large};
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ProfileActionsWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const LogOutButton = styled.button`
    margin-left: ${spacing.xSmall};
`;
