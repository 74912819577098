import styled from 'styled-components';

import { spacing, transparencies } from 'v3/styles/theme';

export const ModalBackground = styled.div`
    position: fixed;
    background-color: ${transparencies.darkJungle80};
    height: 100%;
    width: 100%;
    padding: ${spacing.xxxLarge};
    display: flex;
    overflow: scroll;
    cursor: pointer;
`;

export const CloseButtonWrapper = styled.div`
    position: absolute;
    top: ${spacing.small};
    right: ${spacing.small};
`;
