import styled from 'styled-components';

import MountainTwoPeople from 'v3/assets/mountain-two-people.png';

import { spacing, transparencies } from 'v3/styles/theme';

export const BannerWrapper = styled.div`
    // When using a background image, adding a transparent border will render
    // transparency over the edge the image regardless of how it's positioned
    // because borders exist outside of padding
    //
    // Use an insetted box shadows instead, which is rendered inside of padding
    box-shadow: inset 0 0 0 1px ${transparencies.darkJungle5};
    border-radius: ${spacing.radiusSmall};
    background-image: url(${MountainTwoPeople});
    background-position: top;
    background-size: cover;
    padding: ${spacing.large};
`;

export const SessionListWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;
