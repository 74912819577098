import React, { useCallback, useState } from 'react';

import { DIAGNOSTIC_RATING } from 'v3/libs/constants';

import { supabase, useAuth } from 'v3/hooks/useAuth';
import { useToast } from 'v3/hooks/useToast';

import { Button } from 'v3/components/ui/Button';
import { Select } from 'v3/components/ui/Select';

import { Fonts, spacing } from 'v3/styles/theme';
import {
    CardBottomButtonWrapper,
    NestedCard,
    StandardizedBumper,
} from 'v3/styles/shared';

interface SubmitDiagnosticProps {
    onSubmit: () => Promise<void>;
}

export const SubmitDiagnostic = (props: SubmitDiagnosticProps) => {
    const { onSubmit } = props;

    const { userId } = useAuth();
    const { showSuccessToast, showErrorToast } = useToast();

    const [growthMindset, setGrowthMindset] = useState<string>('');
    const [balanceMeaning, setBalanceMeaning] = useState<string>('');
    const [emotionalIntelligence, setEmotionalIntelligence] =
        useState<string>('');
    const [effectiveCommunication, setEffectiveCommunication] =
        useState<string>('');
    const [selfKnowledge, setSelfKnowledge] = useState<string>('');
    const [feedback, setFeedback] = useState<string>('');
    const [conflictResolution, setConflictResolution] = useState<string>('');
    const [delegation, setDelegation] = useState<string>('');
    const [leadershipConfidence, setLeadershipConfidence] =
        useState<string>('');
    const [coaching, setCoaching] = useState<string>('');
    const [isSubmittingDiagnostic, setIsSubmittingDiagnostic] =
        useState<boolean>(false);

    const onChangeGrowthMindset = useCallback(
        (event: React.FormEvent<HTMLSelectElement>) => {
            const target = event.target as HTMLSelectElement;
            setGrowthMindset(target.value);
        },
        []
    );

    const onChangeBalanceMeaning = useCallback(
        (event: React.FormEvent<HTMLSelectElement>) => {
            const target = event.target as HTMLSelectElement;
            setBalanceMeaning(target.value);
        },
        []
    );

    const onChangeEmotionalIntelligence = useCallback(
        (event: React.FormEvent<HTMLSelectElement>) => {
            const target = event.target as HTMLSelectElement;
            setEmotionalIntelligence(target.value);
        },
        []
    );

    const onChangeEffectiveCommunication = useCallback(
        (event: React.FormEvent<HTMLSelectElement>) => {
            const target = event.target as HTMLSelectElement;
            setEffectiveCommunication(target.value);
        },
        []
    );

    const onChangeSelfKnowledge = useCallback(
        (event: React.FormEvent<HTMLSelectElement>) => {
            const target = event.target as HTMLSelectElement;
            setSelfKnowledge(target.value);
        },
        []
    );

    const onChangeFeedback = useCallback(
        (event: React.FormEvent<HTMLSelectElement>) => {
            const target = event.target as HTMLSelectElement;
            setFeedback(target.value);
        },
        []
    );

    const onChangeConflictResolution = useCallback(
        (event: React.FormEvent<HTMLSelectElement>) => {
            const target = event.target as HTMLSelectElement;
            setConflictResolution(target.value);
        },
        []
    );

    const onChangeDelegation = useCallback(
        (event: React.FormEvent<HTMLSelectElement>) => {
            const target = event.target as HTMLSelectElement;
            setDelegation(target.value);
        },
        []
    );

    const onChangeLeadershipConfidence = useCallback(
        (event: React.FormEvent<HTMLSelectElement>) => {
            const target = event.target as HTMLSelectElement;
            setLeadershipConfidence(target.value);
        },
        []
    );

    const onChangeCoaching = useCallback(
        (event: React.FormEvent<HTMLSelectElement>) => {
            const target = event.target as HTMLSelectElement;
            setCoaching(target.value);
        },
        []
    );

    const internalOnSubmit = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            if (isSubmittingDiagnostic) {
                return;
            }
            setIsSubmittingDiagnostic(true);

            const { error } = await supabase.from('diagnostics').insert({
                created_by: userId,
                growth_mindset: growthMindset,
                balance_meaning: balanceMeaning,
                emotional_intelligence: emotionalIntelligence,
                effective_communication: effectiveCommunication,
                self_knowledge: selfKnowledge,
                feedback,
                conflict_resolution: conflictResolution,
                delegation,
                leadership_confidence: leadershipConfidence,
                coaching,
            });

            if (error == null) {
                showSuccessToast('Successfully submitted diagnostic!');
                onSubmit();
            } else {
                showErrorToast(error.message);
            }

            setIsSubmittingDiagnostic(false);
        },
        [
            isSubmittingDiagnostic,
            userId,
            growthMindset,
            balanceMeaning,
            emotionalIntelligence,
            effectiveCommunication,
            selfKnowledge,
            feedback,
            conflictResolution,
            delegation,
            leadershipConfidence,
            coaching,
            showSuccessToast,
            onSubmit,
            showErrorToast,
        ]
    );

    return (
        <>
            <Fonts.Heading5>{`Diagnostic Survey`}</Fonts.Heading5>
            <StandardizedBumper />
            <Fonts.Medium>
                {`Each of the competencies in the leadership diagnostic relate to topics and conversations designed into The Grand experience.`}
            </Fonts.Medium>
            <StandardizedBumper />
            <Fonts.Medium>
                {`Toward the end of your journey, you'll revisit this diagnostic and see what areas you made progress in and what areas you might want to focus on in coaching next.`}
            </Fonts.Medium>
            <StandardizedBumper />
            <form onSubmit={internalOnSubmit}>
                <NestedCard>
                    <Fonts.Small
                        $isOpenSansSemiBold
                    >{`Growth Mindset:`}</Fonts.Small>
                    <Select
                        onInput={onChangeGrowthMindset}
                        value={growthMindset}
                        label="I approach challenges as opportunities for personal growth and learning."
                        required
                    >
                        <option value="" label="Select" hidden />
                        {DIAGNOSTIC_RATING.map((diagnosticRating) => (
                            <option
                                key={diagnosticRating}
                                value={diagnosticRating}
                            >
                                {diagnosticRating}
                            </option>
                        ))}
                    </Select>
                    <StandardizedBumper />
                    <Fonts.Small
                        $isOpenSansSemiBold
                    >{`Balance / Meaning:`}</Fonts.Small>
                    <Select
                        onInput={onChangeBalanceMeaning}
                        value={balanceMeaning}
                        label="I have a clear sense of what’s important in my life and how that influences the decisions I make."
                        required
                    >
                        <option value="" label="Select" hidden />
                        {DIAGNOSTIC_RATING.map((diagnosticRating) => (
                            <option
                                key={diagnosticRating}
                                value={diagnosticRating}
                            >
                                {diagnosticRating}
                            </option>
                        ))}
                    </Select>
                    <StandardizedBumper />
                    <Fonts.Small
                        $isOpenSansSemiBold
                    >{`Emotional Intelligence:`}</Fonts.Small>
                    <Select
                        onInput={onChangeEmotionalIntelligence}
                        value={emotionalIntelligence}
                        label="I have the capacity to express my feelings and understand others’ emotions at work."
                        required
                    >
                        <option value="" label="Select" hidden />
                        {DIAGNOSTIC_RATING.map((diagnosticRating) => (
                            <option
                                key={diagnosticRating}
                                value={diagnosticRating}
                            >
                                {diagnosticRating}
                            </option>
                        ))}
                    </Select>
                    <StandardizedBumper />
                    <Fonts.Small
                        $isOpenSansSemiBold
                    >{`Effective Communication:`}</Fonts.Small>
                    <Select
                        onInput={onChangeEffectiveCommunication}
                        value={effectiveCommunication}
                        label="I feel equipped to articulate my ideas and express needs with my team."
                        required
                    >
                        <option value="" label="Select" hidden />
                        {DIAGNOSTIC_RATING.map((diagnosticRating) => (
                            <option
                                key={diagnosticRating}
                                value={diagnosticRating}
                            >
                                {diagnosticRating}
                            </option>
                        ))}
                    </Select>
                    <StandardizedBumper />
                    <Fonts.Small
                        $isOpenSansSemiBold
                    >{`Self Knowledge / Awareness:`}</Fonts.Small>
                    <Select
                        onInput={onChangeSelfKnowledge}
                        value={selfKnowledge}
                        label="I feel I have a strong understanding of myself."
                        required
                    >
                        <option value="" label="Select" hidden />
                        {DIAGNOSTIC_RATING.map((diagnosticRating) => (
                            <option
                                key={diagnosticRating}
                                value={diagnosticRating}
                            >
                                {diagnosticRating}
                            </option>
                        ))}
                    </Select>
                    <StandardizedBumper />
                    <Fonts.Small $isOpenSansSemiBold>{`Feedback:`}</Fonts.Small>
                    <Select
                        onInput={onChangeFeedback}
                        value={feedback}
                        label="I am comfortable giving and receiving feedback."
                        required
                    >
                        <option value="" label="Select" hidden />
                        {DIAGNOSTIC_RATING.map((diagnosticRating) => (
                            <option
                                key={diagnosticRating}
                                value={diagnosticRating}
                            >
                                {diagnosticRating}
                            </option>
                        ))}
                    </Select>
                    <StandardizedBumper />
                    <Fonts.Small
                        $isOpenSansSemiBold
                    >{`Conflict Resolution:`}</Fonts.Small>
                    <Select
                        onInput={onChangeConflictResolution}
                        value={conflictResolution}
                        label="I am comfortable resolving conflicts with my team."
                        required
                    >
                        <option value="" label="Select" hidden />
                        {DIAGNOSTIC_RATING.map((diagnosticRating) => (
                            <option
                                key={diagnosticRating}
                                value={diagnosticRating}
                            >
                                {diagnosticRating}
                            </option>
                        ))}
                    </Select>
                    <StandardizedBumper />
                    <Fonts.Small
                        $isOpenSansSemiBold
                    >{`Delegation:`}</Fonts.Small>
                    <Select
                        onInput={onChangeDelegation}
                        value={delegation}
                        label="I am comfortable delegating responsibilities."
                        required
                    >
                        <option value="" label="Select" hidden />
                        {DIAGNOSTIC_RATING.map((diagnosticRating) => (
                            <option
                                key={diagnosticRating}
                                value={diagnosticRating}
                            >
                                {diagnosticRating}
                            </option>
                        ))}
                    </Select>
                    <StandardizedBumper />
                    <Fonts.Small
                        $isOpenSansSemiBold
                    >{`Leadership Confidence:`}</Fonts.Small>
                    <Select
                        onInput={onChangeLeadershipConfidence}
                        value={leadershipConfidence}
                        label="I am confident in my ability to effectively lead and inspire a team."
                        required
                    >
                        <option value="" label="Select" hidden />
                        {DIAGNOSTIC_RATING.map((diagnosticRating) => (
                            <option
                                key={diagnosticRating}
                                value={diagnosticRating}
                            >
                                {diagnosticRating}
                            </option>
                        ))}
                    </Select>
                    <StandardizedBumper />
                    <Fonts.Small $isOpenSansSemiBold>{`Coaching:`}</Fonts.Small>
                    <Select
                        onInput={onChangeCoaching}
                        value={coaching}
                        label="I have the coaching skills and tools to do my work well."
                        required
                    >
                        <option value="" label="Select" hidden />
                        {DIAGNOSTIC_RATING.map((diagnosticRating) => (
                            <option
                                key={diagnosticRating}
                                value={diagnosticRating}
                            >
                                {diagnosticRating}
                            </option>
                        ))}
                    </Select>
                </NestedCard>
                <StandardizedBumper $minHeight={spacing.xLarge} />
                <CardBottomButtonWrapper>
                    <Button
                        type="submit"
                        value="Submit"
                        isLoading={isSubmittingDiagnostic}
                    >
                        {`Submit`}
                    </Button>
                </CardBottomButtonWrapper>
            </form>
        </>
    );
};
