export const CHARACTER_SET =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ123456789' as const;

export const CONSUMER_SPONSOR_IDS = ['6454a86e-941f-4fd2-b173-3fe982a62ea7'];

export const YEARS_EXPERIENCE_OPTIONS = [
    '1-5 years',
    '6-10 years',
    '10+ years',
] as const;

export const FUNDING_STAGE_OPTIONS = [
    'Pre-seed',
    'Seed',
    'Series A',
    'Series B',
    'Series C',
    'Series D',
    'Series E',
    'Series F',
    'Private',
    'Public',
    'Venture series unknown',
    'Nonprofit',
] as const;

export const EMPLOYEE_COUNT_OPTIONS = [
    '1-10 FTE',
    '11-50 FTE',
    '51-200 FTE',
    '200+ FTE',
] as const;

export const COMPANIES_FOUNDED_OPTIONS = [
    '0 (first-time founder)',
    '1',
    '2',
    '3+',
] as const;

export const COACHING_EXPERIENCE_OPTIONS = [
    'Leadership: Expand your Influence at Work',
    'Parenthood: Thriving at Home and Beyond',
    'Career Transitions: Navigate Change, Embrace Your Next Chapter',
] as const;

export const DIAGNOSTIC_RATING = [
    'Strongly Disagree',
    'Disagree',
    'Somewhat Disagree',
    'Neither Agree Nor Disagree',
    'Somewhat Agree',
    'Agree',
    'Strongly Agree',
] as const;

export const RATING_RESPONSES = [
    'Very Satisfied',
    'Satisfied',
    'Neutral',
    'Unsatisfied',
    'Very Unsatisfied',
] as const;

export const EXIT_RESPONSES = [
    'Strongly Agree',
    'Agree',
    'Neutral',
    'Disagree',
    'Strongly Disagree',
] as const;

export const KUDOS_TYPES = {
    ['Self-aware']: '',
    Empathetic: '',
    Confident: '',
    Curious: '',
    Resilient: '',
    ['Effective Coach']: '',
} as const;
