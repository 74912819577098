import React, { useMemo } from 'react';
import { useMatch, useParams } from 'react-router-dom';

import { useAuth } from 'v3/hooks/useAuth';
import { useMemberGroupData } from 'v3/hooks/useMemberGroupData';
import { useNavigateWithParams } from 'v3/hooks/useNavigateWithParams';

import { sortModules } from 'v3/queries/groups';

import { formatSessionDate, getDayCountBetweenDates } from 'v3/libs/utils';

import { Tab, TabControls } from 'v3/components/ui/TabControls';
import { MarkdownWrapper } from 'v3/components/ui/MarkdownWrapper';
import { Tag } from 'v3/components/ui/Tag';

import { colors, Fonts, spacing } from 'v3/styles/theme';
import {
    Card,
    ContentScroller,
    HorizontalFlexWrapper,
    StandardizedBumper,
} from 'v3/styles/shared';

export const SessionDetails = () => {
    const { userProfile } = useAuth();
    const { group, sessionDetails } = useMemberGroupData();
    const { sessionNumber } = useParams();
    const navigateWithParams = useNavigateWithParams();

    const isScheduledSession = sessionNumber == null;

    const baseRoute = isScheduledSession
        ? '/v3/member/scheduled-session'
        : `/v3/member/all-sessions/${sessionNumber}`;

    const descriptionRoute = useMatch(`${baseRoute}/description/*`);
    const reviewRoute = useMatch(`${baseRoute}/review/*`);
    const applyRoute = useMatch(`${baseRoute}/apply/*`);
    const reflectRoute = useMatch(`${baseRoute}/reflect/*`);

    const sessionDetailsDatum = useMemo(
        () =>
            sessionDetails[
                (isScheduledSession
                    ? group?.session_counter ?? 1
                    : parseInt(sessionNumber, 10)) - 1
            ] ?? null,
        [
            group?.session_counter,
            isScheduledSession,
            sessionDetails,
            sessionNumber,
        ]
    );

    const sortedModules = useMemo(
        () =>
            sortModules(
                sessionDetailsDatum?.session_details_modules ?? [],
                sessionDetailsDatum?.modules ?? []
            ),
        [
            sessionDetailsDatum?.modules,
            sessionDetailsDatum?.session_details_modules,
        ]
    );

    const sessionContentoMarkdownStrings: Array<string> = useMemo(() => {
        if (descriptionRoute != null) {
            return (sessionDetailsDatum?.description?.length ?? 0) > 0
                ? [sessionDetailsDatum?.description ?? '']
                : sortedModules?.map((module) => module?.description ?? '') ??
                      [];
        } else if (reviewRoute != null) {
            return (
                sortedModules
                    ?.filter((module) => module?.review_content != null)
                    .map((module) => module?.review_content ?? '') ?? []
            );
        } else if (applyRoute != null) {
            return (
                sortedModules
                    ?.filter((module) => module?.apply_content != null)
                    .map((module) => module?.apply_content ?? '') ?? []
            );
        } else if (reflectRoute != null) {
            return (
                sortedModules
                    ?.filter((module) => module?.reflect_content != null)
                    .map((module) => module?.reflect_content ?? '') ?? []
            );
        } else {
            return [];
        }
    }, [
        applyRoute,
        descriptionRoute,
        reflectRoute,
        reviewRoute,
        sessionDetailsDatum?.description,
        sortedModules,
    ]);

    const sessionDetailsTabs: Array<Tab> = useMemo(
        () => [
            {
                text: 'Description',
                isSelected: descriptionRoute != null,
                onClick: () => navigateWithParams(`${baseRoute}/description`),
            },
            {
                text: 'Review',
                isSelected: reviewRoute != null,
                onClick: () => navigateWithParams(`${baseRoute}/review`),
            },
            {
                text: 'Apply',
                isSelected: applyRoute != null,
                onClick: () => navigateWithParams(`${baseRoute}/apply`),
            },
            {
                text: 'Reflect',
                isSelected: reflectRoute != null,
                onClick: () => navigateWithParams(`${baseRoute}/reflect`),
            },
        ],
        [
            descriptionRoute,
            reviewRoute,
            applyRoute,
            reflectRoute,
            navigateWithParams,
            baseRoute,
        ]
    );

    const daysUntilSessionDate = useMemo(
        () =>
            getDayCountBetweenDates(
                new Date(),
                group?.next_session_date == null
                    ? new Date()
                    : new Date(group.next_session_date)
            ),
        [group?.next_session_date]
    );

    return (
        <ContentScroller>
            <StandardizedBumper $minHeight={spacing.xxxLarge} />
            <StandardizedBumper $minHeight={spacing.xxxLarge} />
            {isScheduledSession ? (
                <>
                    <Fonts.Heading5>{`Hello, ${
                        userProfile?.full_name?.split(' ')[0] ??
                        'Grand Explorer'
                    }`}</Fonts.Heading5>
                    <StandardizedBumper $minHeight={spacing.xxxSmall} />
                    {userProfile?.imagined_future == null ||
                    userProfile.imagined_future === '' ? (
                        <>
                            <Fonts.MediumQuote>{`“Imagination is the beginning of creation. You imagine what you desire, you will what you imagine, and at last, you create what you will.”`}</Fonts.MediumQuote>
                            <StandardizedBumper $minHeight={spacing.xxSmall} />
                            <Fonts.Medium>{`– George Bernard Shaw`}</Fonts.Medium>
                        </>
                    ) : (
                        <>
                            <Fonts.MediumQuote>
                                {`When you joined The Grand, you shared that 6 months from now you hoped you would be... “${userProfile.imagined_future}”`}
                            </Fonts.MediumQuote>
                        </>
                    )}
                    <StandardizedBumper />
                </>
            ) : null}

            <Card>
                <Fonts.XLarge $color={colors.mountainAsh}>
                    {`Session ${
                        isScheduledSession
                            ? group?.session_counter
                            : sessionNumber
                    }`}
                </Fonts.XLarge>
                <StandardizedBumper $minHeight={spacing.xxxSmall} />
                {isScheduledSession ? (
                    <HorizontalFlexWrapper>
                        <Fonts.Medium $color={colors.grandGreen}>
                            {group?.next_session_date == null
                                ? null
                                : formatSessionDate(
                                      new Date(group.next_session_date)
                                  )}
                        </Fonts.Medium>
                        {Math.abs(daysUntilSessionDate) !==
                        daysUntilSessionDate ? null : (
                            <Tag
                                text={
                                    daysUntilSessionDate === 0
                                        ? 'Today'
                                        : `In ${daysUntilSessionDate} days`
                                }
                            />
                        )}
                    </HorizontalFlexWrapper>
                ) : null}
                <StandardizedBumper $minHeight={spacing.medium} />
                <Fonts.Heading5>
                    {sessionDetailsDatum?.title ?? ''}
                </Fonts.Heading5>
                <StandardizedBumper />
                <Fonts.Small $color={colors.grey30}>
                    {`Note: “Learn”, “Apply”, and “Reflect” content will be unlocked at the time of your sessions`}
                </Fonts.Small>
                <StandardizedBumper $minHeight={spacing.small} />
                <TabControls tabs={sessionDetailsTabs} />
                <StandardizedBumper $minHeight={spacing.xLarge} />
                {descriptionRoute == null ? null : (
                    <>
                        <Fonts.Heading7>{`Description`}</Fonts.Heading7>
                        <StandardizedBumper />
                    </>
                )}
                {sessionContentoMarkdownStrings.map((markdownString, index) => (
                    <MarkdownWrapper
                        key={index}
                        markdownString={markdownString}
                        $shouldReduceTopMargin={
                            descriptionRoute != null && index > 0
                        }
                    />
                ))}
            </Card>
            <StandardizedBumper $minHeight={spacing.xxxLarge} />
        </ContentScroller>
    );
};
