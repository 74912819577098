import styled, { css } from 'styled-components';

import { shadows, spacing, transparencies } from 'v3/styles/theme';

interface SessionCardProps {
    $isUpcoming: boolean;
}

export const SessionCardWrapper = styled.button<SessionCardProps>`
    margin-top: ${spacing.large};
    border: 1px solid ${transparencies.success10};
    border-radius: ${spacing.radiusSmall};
    background-color: ${transparencies.success10};
    width: 280px;
    padding: ${spacing.small};
    display: flex;
    flex-direction: column;
    cursor: pointer;
    transition: box-shadow 0.2s;

    &:hover {
        box-shadow: ${shadows.buttonBoxShadow};
    }

    ${(props) => {
        switch (props.$isUpcoming) {
            case true: {
                return css`
                    border-color: ${transparencies.darkJungle5};
                    background-color: ${transparencies.darkJungle5};
                `;
            }
            case false:
            default:
                return null;
        }
    }}
`;

export const ChronologyDataWrapper = styled.div`
    margin-bottom: ${spacing.xSmall};
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const TitleWrapper = styled.div<SessionCardProps>`
    border-radius: ${spacing.radiusSmall};
    background-color: ${transparencies.success10};
    width: 100%;
    padding: ${spacing.xxSmall};
    text-align: left;

    ${(props) => {
        switch (props.$isUpcoming) {
            case true: {
                return css`
                    background-color: ${transparencies.darkJungle5};
                `;
            }
            case false:
            default:
                return null;
        }
    }}
`;
