import React from 'react';
import Markdown from 'react-markdown';

import {
    MarkdownWrapperStyledComponent,
    MarkdownWrapperStyledComponentProps,
    ReactMarkdownOverridingStyles,
} from 'v3/styles/theme';

interface MarkdownWrapperProps extends MarkdownWrapperStyledComponentProps {
    markdownString: string;
}

export const MarkdownWrapper = ({
    markdownString,
    $shouldReduceTopMargin,
}: MarkdownWrapperProps) => {
    return (
        <MarkdownWrapperStyledComponent
            $shouldReduceTopMargin={$shouldReduceTopMargin}
        >
            {/* Need to use a hardcoded class name so overriding styles are applied */}
            <div className="markdownWrapper">
                <ReactMarkdownOverridingStyles />
                <Markdown>{markdownString}</Markdown>
            </div>
        </MarkdownWrapperStyledComponent>
    );
};

export default MarkdownWrapper;
