import React, { useEffect } from 'react';
import { Route, Routes, useMatch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { AuthProvider, useAuth } from 'v3/hooks/useAuth';
import { MemberGroupDataProvider } from 'v3/hooks/useMemberGroupData';
import { ToastProvider } from 'v3/hooks/useToast';
import { useNavigateWithParams } from 'v3/hooks/useNavigateWithParams';

import { SignIn } from 'v3/components/layout/SignIn';
import { SignUp } from 'v3/components/layout/SignUp';
import { PasswordReset } from 'v3/components/layout/PasswordReset';
import { Confirm } from 'v3/components/layout/Confirm';
import { Member } from 'v3/components/layout/Member';
import { OnboardingContainer } from 'v3/components/layout/OnboardingContainer';
import { SessionDetails } from 'v3/components/layout/SessionDetails';
import { SessionList } from 'v3/components/layout/SessionList';
import { Modal } from 'v3/components/layout/Modal';

import { Fonts, GlobalStyles } from 'v3/styles/theme';
import { AppWrapper } from 'v3/styles/app';

const App = () => {
    const { hasInitialized, isSignedIn, userProfile } = useAuth();
    const navigateWithParams = useNavigateWithParams();

    const passwordResetRoute = useMatch('/v3/password-reset/*');
    const confirmRoute = useMatch('/v3/confirm/*');
    const signInRoute = useMatch('/v3/sign-in/*');
    const signUpRoute = useMatch('/v3/sign-up/*');
    const applyRoute = useMatch('/v3/apply/*');
    const sponsorRoute = useMatch('/v3/sponsor/*');
    const coachRoute = useMatch('/v3/coach/*');
    const memberRoute = useMatch('/v3/member/*');
    const onboardingRoute = useMatch('/v3/onboarding/*');

    useEffect(() => {
        if (!hasInitialized) {
            return;
        }

        if (passwordResetRoute == null && confirmRoute == null) {
            if (
                signInRoute == null &&
                signUpRoute == null &&
                applyRoute == null &&
                !isSignedIn
            ) {
                navigateWithParams('/v3/sign-in');
            } else if (userProfile != null) {
                if (
                    sponsorRoute == null &&
                    userProfile?.sponsor_id != null &&
                    userProfile.sponsor_id !== ''
                ) {
                    navigateWithParams('/v3/sponsor');
                } else if (
                    coachRoute == null &&
                    userProfile?.is_coach === true
                ) {
                    navigateWithParams('/v3/coach');
                } else if (
                    memberRoute == null &&
                    (userProfile?.member_groups?.length ?? 0) > 0
                ) {
                    navigateWithParams(
                        '/v3/member/scheduled-session/description'
                    );
                } else if (
                    onboardingRoute == null &&
                    (userProfile?.member_groups?.length ?? 0) === 0
                ) {
                    navigateWithParams('/v3/onboarding');
                }
            }
        }
    }, [
        applyRoute,
        coachRoute,
        confirmRoute,
        hasInitialized,
        isSignedIn,
        memberRoute,
        navigateWithParams,
        onboardingRoute,
        passwordResetRoute,
        signInRoute,
        signUpRoute,
        sponsorRoute,
        userProfile,
        userProfile?.is_coach,
        userProfile?.sponsor_id,
    ]);

    return (
        <>
            <GlobalStyles />
            <AppWrapper
                $isAuthRoute={
                    signInRoute != null ||
                    signUpRoute != null ||
                    applyRoute != null ||
                    passwordResetRoute != null ||
                    confirmRoute != null
                }
            >
                <ToastContainer />
                {hasInitialized ? (
                    <>
                        <Routes>
                            {/* Routes accessible regardless of authentication */}
                            <Route
                                path="password-reset"
                                element={<PasswordReset />}
                            />
                            <Route path="confirm" element={<Confirm />} />

                            {/* Routes accessible only when unauthenticated */}
                            <Route path="sign-in" element={<SignIn />} />
                            <Route
                                path="sign-up/:tempGroupSponsorId?/:sponsorName?"
                                element={<SignUp />}
                            />
                            <Route
                                path="apply/:tempGroupSponsorId?/:sponsorName?"
                                element={<SignUp />}
                            />

                            {/* Routes accessible only to authenticated sponsors */}
                            <Route
                                path="sponsor"
                                element={
                                    <Fonts.Medium>{`Future parent sponsor route`}</Fonts.Medium>
                                }
                            />

                            {/* Routes accessible only to authenticated coaches */}
                            <Route
                                path="coach"
                                element={
                                    <Fonts.Medium>{`Future parent coach route`}</Fonts.Medium>
                                }
                            />

                            {/* Routes accessible only to authenticated members */}
                            <Route path="member" element={<Member />}>
                                <Route
                                    path="scheduled-session/*"
                                    element={<SessionDetails />}
                                />
                                <Route
                                    path="all-sessions"
                                    element={<SessionList />}
                                />
                                <Route
                                    path="all-sessions/:sessionNumber/*"
                                    element={<SessionDetails />}
                                />
                            </Route>

                            {/* Routes accessible only to authenticated users with no app access */}
                            <Route
                                path="onboarding"
                                element={<OnboardingContainer />}
                            />
                        </Routes>

                        {/* Modals are currently only used by signed in member accounts */}
                        {memberRoute == null ? null : <Modal />}
                    </>
                ) : null}
            </AppWrapper>
        </>
    );
};

export const AppWithProviders = () => (
    <ToastProvider>
        <AuthProvider>
            <MemberGroupDataProvider>
                <App />
            </MemberGroupDataProvider>
        </AuthProvider>
    </ToastProvider>
);
