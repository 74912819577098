import styled, { css } from 'styled-components';

import { spacing, transparencies } from 'v3/styles/theme';

interface MenuItemWrapperProps {
    $isSelected: boolean;
}

export const MenuItemWrapper = styled.div<MenuItemWrapperProps>`
    border-radius: ${spacing.radiusSmall};
    background-color: transparent;
    padding: ${spacing.small};
    display: flex;
    justify-content: space-between;
    transition: background-color 0.2s;

    ${(props) => {
        switch (props.$isSelected) {
            case true: {
                return css`
                    background-color: ${transparencies.mountainAsh10};
                `;
            }
            case false:
            default:
                return null;
        }
    }}
`;

export const ItemContentWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-grow: 1;
`;

export const ItemIconWrapper = styled.div`
    margin-right: ${spacing.xSmall};
    display: flex;
`;
