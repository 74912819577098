import React from 'react';

import { ChevronLarge } from 'v3/components/svg/ChevronLarge';

import { Fonts, colors, SVGComponent } from 'v3/styles/theme';
import {
    ItemContentWrapper,
    ItemIconWrapper,
    MenuItemWrapper,
} from 'v3/styles/menuItem';

interface MenuItemProps {
    isSelected: boolean;
    SVGComponent: SVGComponent;
    text: string;
}

export const MenuItem = ({ isSelected, SVGComponent, text }: MenuItemProps) => (
    <MenuItemWrapper $isSelected={isSelected}>
        <ItemContentWrapper>
            <ItemIconWrapper>
                <SVGComponent
                    color={isSelected ? colors.mountainAsh : colors.darkJungle}
                />
            </ItemIconWrapper>
            <Fonts.Small
                $color={isSelected ? colors.mountainAsh : colors.darkJungle}
            >
                {text}
            </Fonts.Small>
        </ItemContentWrapper>
        <ChevronLarge
            color={isSelected ? colors.mountainAsh : colors.darkJungle}
        />
    </MenuItemWrapper>
);
