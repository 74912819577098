import styled, { css } from 'styled-components';

import { colors, shadows, spacing, transparencies } from 'v3/styles/theme';

export interface ButtonProps {
    $isBackButton?: boolean;
    $isLoading?: boolean;
    $isDisabled?: boolean;
    $style?: 'fill' | 'outline' | 'float';
    $stretch?: boolean;
}

export const Button = styled.button<ButtonProps>`
    position: relative;
    outline: 0;
    border: 1px solid ${colors.grandGreen};
    border-radius: ${spacing.radiusSmall};
    background-color: ${colors.grandGreen};
    padding: ${spacing.xSmall} ${spacing.small};
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${colors.white};
    cursor: pointer;
    transition: box-shadow 0.2s, background-color 0.2s;

    &:hover {
        box-shadow: ${shadows.buttonBoxShadow};
    }

    ${(props) => {
        switch (props.$isBackButton) {
            case true: {
                return css`
                    // Customized padding for symmetry
                    padding: ${spacing.xxSmall} 10px;
                    transform: scaleX(-1);
                `;
            }
            case false:
            default: {
                return;
            }
        }
    }};

    ${(props) => {
        switch (props.$isLoading) {
            case true: {
                return css`
                    padding: 0 0 3px;
                    pointer-events: none;
                `;
            }
            case false:
            default: {
                return;
            }
        }
    }};

    ${(props) => {
        switch (props.$isDisabled) {
            case true: {
                return css`
                    opacity: 0.3;
                    pointer-events: none;
                `;
            }
            case false:
            default: {
                return;
            }
        }
    }};

    ${(props) => {
        switch (props.$style) {
            case 'outline': {
                return css`
                    background-color: transparent;
                    color: ${colors.grandGreen};

                    &:hover {
                        background-color: ${transparencies.darkJungle10};
                        box-shadow: none;
                    }
                `;
            }
            case 'float': {
                return css`
                    border-color: transparent;
                    background-color: transparent;
                    color: ${colors.grandGreen};

                    &:hover {
                        box-shadow: none;
                    }
                `;
            }
            case 'fill':
            default: {
                return;
            }
        }
    }};

    ${(props) => {
        switch (props.$stretch) {
            case true: {
                return css`
                    width: 100%;
                `;
            }
            case false:
            default: {
                return;
            }
        }
    }};
`;
