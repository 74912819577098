import React from 'react';

import { CustomSVGProps, setSVGColor } from 'v3/styles/theme';

export const ChevronLarge = (props: CustomSVGProps) => (
    <svg
        width={10}
        height={20}
        viewBox="0 0 10 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M0.500001 1L9.5 10L0.5 19"
            stroke={setSVGColor(props)}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
