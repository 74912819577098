import React, {
    createContext,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';
import { AuthError, createClient } from '@supabase/supabase-js';

import { useToast } from 'hooks/useToast';

import { Database } from 'libs/supabaseTypes';

const { REACT_APP_SUPABASE_URL, REACT_APP_SUPABASE_ANON_KEY } = process.env;

export const supabase = createClient<Database>(
    REACT_APP_SUPABASE_URL ?? '',
    REACT_APP_SUPABASE_ANON_KEY ?? ''
);

export type ProfileData = Database['public']['Tables']['profiles']['Row'];

interface AuthContextState {
    signUp:
        | (({
              email,
              password,
              captchaToken,
              fullName,
              tempGroupSponsorId,
          }: {
              email: string;
              password: string;
              captchaToken: string;
              fullName: string;
              tempGroupSponsorId: string;
          }) => Promise<AuthError | null>)
        | null;
    signInWithPassword:
        | (({
              email,
              password,
              captchaToken,
          }: {
              email: string;
              password: string;
              captchaToken: string;
          }) => Promise<AuthError | null>)
        | null;
    verifyAccount:
        | (({
              verificationCode,
              email,
              type,
          }: {
              verificationCode: string;
              email: string;
              type: 'invite' | 'recovery' | 'email' | null;
          }) => Promise<AuthError | null>)
        | null;
    signOut: (() => Promise<void>) | null;
    hasInitialized: boolean;
    isSignedIn: boolean;
    userId: string;
    userEmail: string;
    userCreatedAt: string;
    getProfiles: (() => void) | null;
    userProfile: ProfileData | null;
    groupProfiles: Array<ProfileData>;
}

const authDefaultState: AuthContextState = {
    hasInitialized: false,
    signUp: null,
    signInWithPassword: null,
    verifyAccount: null,
    signOut: null,
    isSignedIn: false,
    userId: '',
    userEmail: '',
    userCreatedAt: '',
    getProfiles: null,
    userProfile: null,
    groupProfiles: [],
};

const AuthContext = createContext(authDefaultState);

export const AuthProvider = ({ children }: PropsWithChildren) => {
    const { showErrorToast } = useToast();

    const [hasInitialized, setHasInitialized] = useState<boolean>(false);
    const [isSignedIn, setIsSignedIn] = useState<boolean>(false);
    const [userId, setUserId] = useState<string>('');
    const [userEmail, setUserEmail] = useState<string>('');
    const [userCreatedAt, setUserCreatedAt] = useState<string>('');
    const [userProfile, setUserProfile] = useState<ProfileData | null>(null);
    const [groupProfiles, setGroupProfiles] = useState<Array<ProfileData>>([]);

    useEffect(() => {
        const checkIsSignedIn = async () => {
            const { data, error } = await supabase.auth.getSession();
            if (error == null && data.session != null) {
                setIsSignedIn(true);
            }
            setHasInitialized(true);
        };
        checkIsSignedIn();
    }, []);

    const signUp = useCallback(
        async ({
            email,
            password,
            captchaToken,
            fullName,
            tempGroupSponsorId,
        }: {
            email: string;
            password: string;
            captchaToken: string;
            fullName: string;
            tempGroupSponsorId: string;
        }) => {
            const { error } = await supabase.auth.signUp({
                email,
                password,
                options: {
                    captchaToken,
                    data: {
                        full_name: fullName,
                        temp_group_sponsor_id: tempGroupSponsorId,
                    },
                },
            });

            return error;
        },
        []
    );

    const signInWithPassword = useCallback(
        async ({
            email,
            password,
            captchaToken,
        }: {
            email: string;
            password: string;
            captchaToken: string;
        }) => {
            const { error } = await supabase.auth.signInWithPassword({
                email,
                password,
                options: { captchaToken },
            });

            if (error == null) {
                setIsSignedIn(true);
            }

            return error;
        },
        []
    );

    const verifyAccount = useCallback(
        async ({
            verificationCode,
            email,
            type,
        }: {
            verificationCode: string;
            email: string;
            type: 'invite' | 'recovery' | 'email' | null;
        }) => {
            const { error } = await supabase.auth.verifyOtp({
                token: verificationCode,
                email,
                type: type ?? 'recovery',
            });

            if (error == null) {
                setIsSignedIn(true);
            }

            return error;
        },
        []
    );

    const signOut = useCallback(async () => {
        const { error } = await supabase.auth.signOut();

        if (error == null) {
            setIsSignedIn(false);
            setUserId('');
            setUserEmail('');
            setUserProfile(null);
            setGroupProfiles([]);
        } else {
            showErrorToast(error.message);
        }
    }, [showErrorToast]);

    useEffect(() => {
        const getUserData = async () => {
            const {
                data: { user },
                error,
            } = await supabase.auth.getUser();

            if (error == null) {
                setUserId(user?.id ?? '');
                setUserEmail(user?.email ?? '');
                setUserCreatedAt(user?.created_at ?? '');
            } else {
                signOut();
                showErrorToast(error.message);
            }
        };

        if (isSignedIn) {
            getUserData();
        }
    }, [isSignedIn, showErrorToast, signOut]);

    const getProfiles = useCallback(async () => {
        const { data: userProfileData, error: userProfileError } =
            await supabase.from('profiles').select('*').eq('id', userId);

        const { data: memberProfilesData, error: memberProfilesError } =
            await supabase
                .from('profiles')
                .select('*')
                .containedBy(
                    'member_groups',
                    userProfileData?.[0]?.is_coach
                        ? userProfileData?.[0]?.coach_groups ?? []
                        : userProfileData?.[0]?.member_groups ?? []
                )
                .neq('id', userId);

        const { data: coachProfilesData, error: coachProfilesError } =
            await supabase
                .from('profiles')
                .select('*')
                .eq('is_coach', true)
                .containedBy(
                    'coach_groups',
                    userProfileData?.[0]?.is_coach
                        ? userProfileData?.[0]?.coach_groups ?? []
                        : userProfileData?.[0]?.member_groups ?? []
                )
                .neq('id', userId);

        const filteredMemberProfilesData =
            memberProfilesData?.filter(
                (memberProfile) => memberProfile.is_test !== true
            ) ?? [];
        const filteredCoachProfilesData =
            coachProfilesData?.filter(
                (coachProfile) => coachProfile.is_test !== true
            ) ?? [];

        if (
            memberProfilesError == null &&
            userProfileError == null &&
            coachProfilesError == null
        ) {
            filteredMemberProfilesData.unshift(...filteredCoachProfilesData);
            if (userProfileData[0] != null) {
                filteredMemberProfilesData.unshift(userProfileData[0]);
            }
            setUserProfile(userProfileData[0] ?? null);
            setGroupProfiles(filteredMemberProfilesData);
        } else {
            if (userProfileError != null) {
                showErrorToast(userProfileError.message ?? '');
            }
            if (memberProfilesError != null) {
                showErrorToast(memberProfilesError.message ?? '');
            }
            if (coachProfilesError != null) {
                showErrorToast(coachProfilesError.message ?? '');
            }
        }
    }, [showErrorToast, userId]);

    useEffect(() => {
        if (userId !== '') {
            getProfiles();
        } else {
            setGroupProfiles([]);
        }
    }, [getProfiles, userId]);

    return (
        <AuthContext.Provider
            value={{
                hasInitialized,
                signUp,
                signInWithPassword,
                verifyAccount,
                signOut,
                isSignedIn,
                userId,
                userEmail,
                userCreatedAt,
                getProfiles,
                userProfile,
                groupProfiles,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
