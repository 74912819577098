import React from 'react';
import { Turnstile, TurnstileInstance } from '@marsidev/react-turnstile';

import {
    TurnstileInnerBorderCover,
    TurnstileOuterBorderCover,
    TurnstileWrapper as StyledTurnstileWrapper,
} from 'v3/styles/turnstileWrapper';

interface TurnstileWrapperProps {
    turnstileRef: React.MutableRefObject<TurnstileInstance | undefined>;
    onSuccess: (token: string) => void;
}

export const TurnstileWrapper = ({
    turnstileRef,
    onSuccess,
}: TurnstileWrapperProps) => (
    // Use multiple div overlays with individual border styling to mock a border radius
    // and border color following our design system since the Turnstile component
    // does not allow for custom styling
    <StyledTurnstileWrapper>
        <Turnstile
            ref={turnstileRef}
            siteKey="0x4AAAAAAAHdBQdhmUCPmC2X"
            onSuccess={onSuccess}
            options={{
                theme: 'light',
            }}
        />
        <TurnstileOuterBorderCover>
            <TurnstileInnerBorderCover />
        </TurnstileOuterBorderCover>
    </StyledTurnstileWrapper>
);
