import React from 'react';

import { colors, Fonts } from 'v3/styles/theme';
import { TabControlsWrapper, TabWrapper } from 'v3/styles/tabControls';

export interface Tab {
    text: string;
    isSelected: boolean;
    onClick: () => void;
}

interface TabControlsProps {
    tabs: Array<Tab>;
}

export const TabControls = ({ tabs }: TabControlsProps) => {
    return (
        <TabControlsWrapper>
            {tabs.map((tab) => (
                <TabWrapper
                    key={tab.text}
                    onClick={tab.onClick}
                    $isSelected={tab.isSelected}
                >
                    <Fonts.Medium
                        $color={
                            tab.isSelected
                                ? colors.mountainAsh
                                : colors.grandGreen
                        }
                    >
                        {tab.text}
                    </Fonts.Medium>
                </TabWrapper>
            ))}
        </TabControlsWrapper>
    );
};
