import React, { useEffect } from 'react';
import { Outlet, useMatch } from 'react-router-dom';

import { useNavigateWithParams } from 'v3/hooks/useNavigateWithParams';

import { NavMenu } from 'v3/components/layout/NavMenu';

import { MainViewWrapper } from 'v3/styles/shared';

export const Member = () => {
    const navigateWithParams = useNavigateWithParams();

    const memberWithNoSubRoute = useMatch('/v3/member');

    useEffect(() => {
        if (memberWithNoSubRoute != null) {
            navigateWithParams('/v3/member/scheduled-session/description');
        }
    }, [memberWithNoSubRoute, navigateWithParams]);

    return (
        <MainViewWrapper>
            <NavMenu />
            <Outlet />
        </MainViewWrapper>
    );
};
