import { CHARACTER_SET } from 'v3/libs/constants';

export const generateRandomString = () => {
    let randomString = '';
    for (let i = 0; i < 10; i++) {
        randomString += CHARACTER_SET.charAt(
            Math.floor(Math.random() * CHARACTER_SET.length)
        );
    }
    return randomString;
};

export const formatSessionDate = (date: Date) => {
    const formattedDate = new Intl.DateTimeFormat('en-US', {
        dateStyle: 'medium',
        timeStyle: 'medium',
    }).format(date);

    return `${formattedDate.slice(0, formattedDate.length - 6)}${formattedDate
        .slice(formattedDate.length - 2, formattedDate.length)
        .toLowerCase()}`;
};

export const getDayCountBetweenDates = (date1: Date, date2: Date) => {
    const utc1 = Date.UTC(
        date1.getFullYear(),
        date1.getMonth(),
        date1.getDate()
    );
    const utc2 = Date.UTC(
        date2.getFullYear(),
        date2.getMonth(),
        date2.getDate()
    );

    return Math.floor((utc2 - utc1) / (1000 * 60 * 60 * 24));
};

export type RelationshipToToday = 'previous' | 'today' | 'upcoming';

export const getRelationshipToToday = (date: Date): RelationshipToToday => {
    const dayCountBetweenDates = getDayCountBetweenDates(date, new Date());
    if (dayCountBetweenDates === 0) {
        return 'today';
    } else if (Math.abs(dayCountBetweenDates) !== dayCountBetweenDates) {
        return 'previous';
    } else {
        return 'upcoming';
    }
};
