import React from 'react';

import {
    LoaderWrapper,
    LoadingRingOne,
    LoadingRingTwo,
    LoadingRingThree,
} from 'v3/styles/loader';

export const Loader = () => {
    return (
        <LoaderWrapper>
            <LoadingRingOne />
            <LoadingRingTwo />
            <LoadingRingThree />
        </LoaderWrapper>
    );
};
