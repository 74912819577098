import styled, { css } from 'styled-components';

import {
    spacing,
    colors,
    transparencies,
    shadows,
    Fonts,
    Spacing,
} from 'v3/styles/theme';

export const MainViewWrapper = styled.div`
    display: flex;
    overflow: hidden;
`;

export const ContentScroller = styled.div`
    width: 650px;
    max-height: 100vh;
    overflow: scroll;
`;

export const FullBleedScroller = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: scroll;
`;

export const HorizontalFlexWrapper = styled.div`
    display: flex;
    align-items: center;
`;

interface CardProps {
    $margin?: string;
    $width?: string;
    $padding?: Spacing;
}

export const Card = styled.div<CardProps>`
    position: relative;
    box-shadow: ${shadows.smallBase};
    border-radius: ${spacing.radiusLarge};
    border: 1px solid ${transparencies.darkJungle5};
    background-color: ${colors.white};
    padding: ${spacing.xLarge};
    display: flex;
    flex-direction: column;
    cursor: default;

    ${(props) =>
        props.$margin == null
            ? null
            : css`
                  margin: ${props.$margin};
              `}

    ${(props) =>
        props.$width == null
            ? null
            : css`
                  width: ${props.$width};
              `}

    ${(props) =>
        props.$padding == null
            ? null
            : css`
                  padding: ${props.$padding};
              `};
`;

export const NestedCard = styled.div`
    border: 1px solid ${transparencies.darkJungle5};
    border-radius: ${spacing.radiusSmall};
    background-color: ${transparencies.darkJungle5};
    padding: ${spacing.small};
    display: flex;
    flex-direction: column;
`;

interface CardBottomButtonWrapperProps {
    $backgroundColor?: 'white' | 'light';
}

export const CardBottomButtonWrapper = styled.div<CardBottomButtonWrapperProps>`
    margin: 0 -${spacing.xLarge} -${spacing.xLarge};
    border-bottom-left-radius: ${spacing.radiusMedium};
    border-bottom-right-radius: ${spacing.radiusMedium};
    background-color: ${colors.light};
    width: calc(100% + ${spacing.xLarge} + ${spacing.xLarge});
    padding: ${spacing.xLarge};
    display: flex;
    justify-content: flex-end;

    ${(props) => {
        switch (props.$backgroundColor) {
            case 'white': {
                return css`
                    background-color: ${colors.white};
                    padding-top: 0;
                `;
            }
            case 'light':
            default: {
                return;
            }
        }
    }};
`;

interface StandardizedBumperProps {
    $minHeight?: Spacing;
    $minWidth?: Spacing;
}

export const StandardizedBumper = styled.div<StandardizedBumperProps>`
    min-height: ${spacing.large};

    ${(props) =>
        props.$minHeight == null
            ? null
            : css`
                  min-height: ${props.$minHeight};
              `};

    ${(props) =>
        props.$minWidth == null
            ? null
            : css`
                  min-width: ${props.$minWidth};
              `};
`;

export const Divider = styled.div`
    margin: ${spacing.small} 0;
    background-color: ${transparencies.darkJungle5};
    width: 100%;
    height: 1px;
`;

export const Label = styled(Fonts.Small)`
    margin-bottom: ${spacing.xxSmall};
`;

export const SubLabel = styled(Fonts.XSmall)`
    margin-bottom: ${spacing.xxSmall};
    color: ${colors.grandGreen};
`;

export const CheckboxWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
`;

export const Checkbox = styled.input`
    margin-right: ${spacing.xSmall};
    min-width: ${spacing.small};
    outline: none;
    min-height: ${spacing.small};
    cursor: pointer;

    &:checked {
        accent-color: ${colors.grandGreen};
    }
`;
