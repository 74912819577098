import styled, { css } from 'styled-components';

import MainBackgroundImageDesktop from 'v3/assets/bckg-landscape-desktop.jpg';
import MainBackgroundImageMobile from 'v3//assets/bckg-landscape-mobile.jpg';
import OnboardingBackgroundImageDesktop from 'v3/assets/bckg-clouds-desktop.jpg';
import OnboardingBackgroundImageMobile from 'v3//assets/bckg-clouds-mobile.jpg';

import { mobileCssQuerist } from 'v3/styles/theme';

interface AppWrapperProps {
    $isAuthRoute: boolean;
}

export const AppWrapper = styled.div<AppWrapperProps>`
    position: relative;
    background-image: url(${MainBackgroundImageDesktop});
    background-position: center;
    background-size: cover;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    ${mobileCssQuerist(css`
        background-image: url(${MainBackgroundImageMobile});
    `)}

    ${(props) => {
        switch (props.$isAuthRoute) {
            case true: {
                return css`
                    background-image: url(${OnboardingBackgroundImageDesktop});

                    ${mobileCssQuerist(css`
                        background-image: url(${OnboardingBackgroundImageMobile});
                    `)}
                `;
            }
            case false:
            default: {
                return null;
            }
        }
    }};
`;
