import React from 'react';

import { CustomSVGProps, setSVGColor } from 'v3/styles/theme';

export const Unlock = (props: CustomSVGProps) => (
    <svg
        width={20}
        height={20}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M0.5 10.0769H2.68221e-07H0.5ZM0.5 16.5H1H0.5ZM16.5 19.5V20V19.5ZM3.5 19.5V20V19.5ZM19.5 10.0769H19H19.5ZM19.5 16.5H20H19.5ZM16.5 7.07694V7.57694V7.07694ZM3.5 7.07693L3.5 7.57693H3.5L3.5 7.07693ZM3.65385 6.34615C3.65385 6.6223 3.8777 6.84615 4.15385 6.84615C4.42999 6.84615 4.65385 6.6223 4.65385 6.34615H3.65385ZM13.3411 2.17235C13.5566 2.34507 13.8713 2.31042 14.044 2.09496C14.2167 1.8795 14.182 1.56482 13.9666 1.3921L13.3411 2.17235ZM9.5 14.3846C9.5 14.6608 9.72386 14.8846 10 14.8846C10.2761 14.8846 10.5 14.6608 10.5 14.3846H9.5ZM10.5 11.4615C10.5 11.1854 10.2761 10.9615 10 10.9615C9.72386 10.9615 9.5 11.1854 9.5 11.4615H10.5ZM2.68221e-07 10.0769L0 16.5H1L1 10.0769H2.68221e-07ZM16.5 19L3.5 19V20L16.5 20V19ZM19 10.0769V16.5H20V10.0769H19ZM16.5 6.57694L3.50001 6.57693L3.5 7.57693L16.5 7.57694V6.57694ZM20 10.0769C20 8.14395 18.433 6.57695 16.5 6.57694V7.57694C17.8807 7.57695 19 8.69623 19 10.0769H20ZM1 10.0769C1 8.69621 2.11929 7.57692 3.5 7.57693L3.50001 6.57693C1.56701 6.57692 3.57628e-07 8.14393 2.68221e-07 10.0769H1ZM0 16.5C-5.96046e-08 18.433 1.567 20 3.5 20V19C2.11929 19 1 17.8807 1 16.5H0ZM16.5 20C18.433 20 20 18.433 20 16.5H19C19 17.8807 17.8807 19 16.5 19V20ZM4.65385 6.34615C4.65385 3.39355 7.0474 1 10 1V0C6.49512 0 3.65385 2.84127 3.65385 6.34615H4.65385ZM10 1C11.2648 1 12.4258 1.43858 13.3411 2.17235L13.9666 1.3921C12.8802 0.521241 11.5003 0 10 0V1ZM10.5 14.3846L10.5 11.4615H9.5L9.5 14.3846H10.5Z"
            fill={setSVGColor(props)}
        />
    </svg>
);
