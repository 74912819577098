import styled, { css } from 'styled-components';

import { colors, spacing, transparencies } from 'v3/styles/theme';

const TAB_CONTROL_WRAPPER_BORDER_WIDTH = '1px';

export const TabControlsWrapper = styled.div`
    border-bottom: ${TAB_CONTROL_WRAPPER_BORDER_WIDTH} solid
        ${transparencies.darkJungle20};
    width: 100%;
    display: flex;
    justify-content: space-between;
`;

interface TabWrapperProps {
    $isSelected: boolean;
}

export const TabWrapper = styled.button<TabWrapperProps>`
    position: relative;
    bottom: -${TAB_CONTROL_WRAPPER_BORDER_WIDTH};
    padding: 0 ${spacing.xSmall} ${spacing.xxSmall};
    border-bottom: 2px solid transparent;
    transition: border-bottom 0.2s;

    ${(props) => {
        switch (props.$isSelected) {
            case true: {
                return css`
                    border-bottom: 2px solid ${colors.mountainAsh};
                `;
            }
            case false:
            default:
                return null;
        }
    }};
`;
