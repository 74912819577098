import React, {
    createContext,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';

import { supabase, useAuth } from 'v3/hooks/useAuth';
import { useToast } from 'v3/hooks/useToast';

import {
    getGroups,
    groupProgramHistoryQuery,
    sortSessionDetails,
} from 'v3/queries/groups';

import { Database } from 'v3/libs/supabaseTypes';
import {} from 'queries/groups';

type GroupData = Database['public']['Tables']['groups']['Row'];
type ClosingData = Database['public']['Tables']['closings']['Row'];

interface MemberGroupDataContext {
    group: GroupData | null;
    getGroup: (() => Promise<void>) | null;
    sessionDetails: ReturnType<typeof sortSessionDetails>;
    getSessionDetails: (() => Promise<void>) | null;
    closings: Array<ClosingData>;
    getClosings: (() => Promise<void>) | null;
}

const memberGroupDataDefaultState: MemberGroupDataContext = {
    group: null,
    getGroup: null,
    sessionDetails: [],
    getSessionDetails: null,
    closings: [],
    getClosings: null,
};

const MemberGroupDataContext = createContext(memberGroupDataDefaultState);

export function MemberGroupDataProvider({ children }: PropsWithChildren) {
    const { userId } = useAuth();
    const { showErrorToast } = useToast();

    const [group, setGroup] = useState<GroupData | null>(null);
    const [sessionDetails, setSessionDetails] = useState<
        ReturnType<typeof sortSessionDetails>
    >([]);
    const [closings, setClosings] = useState<Array<ClosingData>>([]);

    const getGroup = useCallback(async () => {
        const { data, error } = await getGroups;

        if (error == null) {
            setGroup(data?.[0] ?? null);
        } else {
            showErrorToast(error.message);
        }
    }, [showErrorToast]);

    useEffect(() => {
        if (userId !== '') {
            getGroup();
        }
    }, [getGroup, userId]);

    const getSessionDetails = useCallback(async () => {
        const { data, error } = await groupProgramHistoryQuery.eq(
            'id',
            group?.group_program_id ?? ''
        );

        if (error == null) {
            setSessionDetails(sortSessionDetails(data));
        } else {
            showErrorToast(error.message);
        }
    }, [group?.group_program_id, showErrorToast]);

    useEffect(() => {
        if (group != null && group?.group_program_id != null) {
            getSessionDetails();
        }
    }, [getSessionDetails, group]);

    const getClosings = useCallback(async () => {
        // an RLS policy restricts access only to closings that have been submitted for
        // a group you're in, so this returns learnings submitted by all group members
        const { data, error } = await supabase
            .from('closings')
            .select('*')
            .order('session_date', { ascending: false });

        if (error == null) {
            setClosings(data);
        } else {
            showErrorToast(error.message);
        }
    }, [showErrorToast]);

    return (
        <MemberGroupDataContext.Provider
            value={{
                group,
                getGroup,
                sessionDetails,
                getSessionDetails,
                closings,
                getClosings,
            }}
        >
            {children}
        </MemberGroupDataContext.Provider>
    );
}

export const useMemberGroupData = () => useContext(MemberGroupDataContext);
