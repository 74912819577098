import React from 'react';

import { CustomSVGProps, setSVGColor } from 'v3/styles/theme';

export const ChevronSmall = (props: CustomSVGProps) => (
    <svg
        width={6}
        height={10}
        viewBox="0 0 6 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M0.75 0.5L5.25 5L0.75 9.5"
            stroke={setSVGColor(props)}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
