import React, { useCallback, useRef, useState } from 'react';
import { TurnstileInstance } from '@marsidev/react-turnstile';

import { useToast } from 'v3/hooks/useToast';

import { TextInput } from 'v3/components/ui/TextInput';
import { TurnstileWrapper } from 'v3/components/ui/TurnstileWrapper';
import { Button } from 'v3/components/ui/Button';

import { GrandLogoVert } from 'v3/components/svg/GrandLogoVert';

import { colors, Fonts, transparencies } from 'v3/styles/theme';
import { StandardizedBumper } from 'v3/styles/shared';
import {
    AuthForm,
    AuthWrapper,
    StepsCompleteTextWrapper,
} from 'v3/styles/auth';

export const PasswordReset = () => {
    const { showErrorToast } = useToast();

    const [email, setEmail] = useState<string | null>(null);
    const [captchaToken, setCaptchaToken] = useState<string | null>(null);
    const [hasSubmittedRequest, setHasSubmittedRequest] =
        useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [isSubmittingRequest, setIsSubmittingRequest] =
        useState<boolean>(false);

    const turnstileRef = useRef<TurnstileInstance>();

    const onEmailInput = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            setEmail(target.value);

            if (isError) {
                setIsError(false);
            }
        },
        [isError]
    );

    const onSubmit = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            if (isSubmittingRequest) {
                return;
            }
            setIsSubmittingRequest(true);

            if (captchaToken != null) {
                const response = await fetch(
                    'https://us-central1-the-grand-app.cloudfunctions.net/gpreset_public',
                    {
                        method: 'POST',
                        body: JSON.stringify({
                            email: email,
                            token: captchaToken,
                        }),
                    }
                );

                if (response.status === 200) {
                    setHasSubmittedRequest(true);
                } else {
                    turnstileRef.current?.reset();
                    setIsError(true);
                    showErrorToast('Email not found');
                }
            } else {
                showErrorToast(
                    'CAPTCHA verification is required to reset password'
                );
            }

            setIsSubmittingRequest(false);
        },
        [captchaToken, email, isSubmittingRequest, showErrorToast]
    );

    return (
        <AuthWrapper>
            <GrandLogoVert width={140} />
            <StandardizedBumper />
            {!hasSubmittedRequest ? (
                <AuthForm onSubmit={onSubmit}>
                    <Fonts.Heading6
                        $color={colors.mountainAsh}
                    >{`Reset password`}</Fonts.Heading6>
                    <StandardizedBumper />
                    <Fonts.Medium $textAlign="center">
                        {`Please enter your email to reset your password or get a new verification code`}
                    </Fonts.Medium>
                    <StandardizedBumper />
                    <TextInput
                        type="email"
                        id="email"
                        name="email"
                        onInput={onEmailInput}
                        label="Email address"
                        isError={isError}
                        required
                        $backgroundColor={transparencies.darkJungle5}
                    />
                    <StandardizedBumper />
                    <TurnstileWrapper
                        turnstileRef={turnstileRef}
                        onSuccess={setCaptchaToken}
                    />
                    <StandardizedBumper />
                    <Button
                        type="submit"
                        value="Submit"
                        isLoading={isSubmittingRequest}
                        $stretch
                    >
                        {`Submit`}
                    </Button>
                </AuthForm>
            ) : (
                <StepsCompleteTextWrapper>
                    <Fonts.Heading6 $textAlign="center">{`Instructions sent to your email`}</Fonts.Heading6>
                    <StandardizedBumper />
                    <Fonts.Medium $textAlign="center">
                        {`Please check your email for reset instructions. If you need additional support please email hi@thegrand.world`}
                    </Fonts.Medium>
                    <StandardizedBumper />
                    <StandardizedBumper />
                    <StandardizedBumper />
                    <StandardizedBumper />
                    <StandardizedBumper />
                    <StandardizedBumper />
                    <StandardizedBumper />
                    <StandardizedBumper />
                    <StandardizedBumper />
                </StepsCompleteTextWrapper>
            )}
        </AuthWrapper>
    );
};
