import React, { useCallback, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { supabase, useAuth } from 'v3/hooks/useAuth';
import { useToast } from 'v3/hooks/useToast';

import { RequiredAsterisk } from 'v3/components/ui/RequiredAsterisk';
import { TextInput } from 'v3/components/ui/TextInput';
import { Button } from 'v3/components/ui/Button';

import { GrandLogoVert } from 'v3/components/svg/GrandLogoVert';

import { colors, Fonts, transparencies } from 'v3/styles/theme';
import {
    Checkbox,
    CheckboxWrapper,
    StandardizedBumper,
} from 'v3/styles/shared';
import {
    AuthForm,
    AuthWrapper,
    StepsCompleteTextWrapper,
} from 'v3/styles/auth';

export const Confirm = () => {
    const { search } = useLocation();
    const { verifyAccount, userProfile } = useAuth();
    const { showErrorToast } = useToast();

    const [verificationCode, setVerificationCode] = useState<string>('');
    const [isVerified, setIsVerified] = useState<boolean>(false);
    const [hasAcceptedTerms, setHasAcceptedTerms] = useState<boolean>(false);
    const [password, setPassword] = useState<string>('');
    const [confirmedPassword, setConfirmedPassword] = useState<string>('');
    const [isPasswordConfirmed, setIsPasswordConfirmed] =
        useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const { email, type } = useMemo(() => {
        const urlParams = new URLSearchParams(search);
        return {
            email: urlParams.get('email') ?? '',
            type: urlParams.get('type') as 'invite' | 'recovery' | null,
        };
    }, [search]);

    const onVerificationCodeInput = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            setVerificationCode(target.value);

            if (isError) {
                setIsError(false);
            }
        },
        [isError]
    );

    const onToggleAcceptTerms = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            setHasAcceptedTerms(target.checked);
        },
        []
    );

    const onVerifyAccount = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            if (isSubmitting) {
                return;
            }
            setIsSubmitting(true);

            const error = await verifyAccount?.({
                verificationCode,
                email,
                type,
            });

            if (error == null) {
                setIsVerified(true);
            } else {
                setIsError(true);
                showErrorToast(error.message);
            }

            setIsSubmitting(false);
        },
        [
            email,
            isSubmitting,
            showErrorToast,
            type,
            verificationCode,
            verifyAccount,
        ]
    );

    const onPasswordInput = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            setPassword(target.value);

            if (isError) {
                setIsError(false);
            }
        },
        [isError]
    );

    const onConfirmPasswordInput = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            setConfirmedPassword(target.value);

            if (isError) {
                setIsError(false);
            }
        },
        [isError]
    );

    const onSetPassword = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            if (isSubmitting) {
                return;
            }
            setIsSubmitting(true);

            if (password.length < 8) {
                setIsError(true);
                showErrorToast('Your password must be at least 8 characters');
                setIsSubmitting(false);
                return;
            }

            if (password !== confirmedPassword) {
                setIsError(true);
                showErrorToast('Your passwords do not match');
                setIsSubmitting(false);
                return;
            }

            const { error } = await supabase.auth.updateUser({
                password,
            });

            if (error != null) {
                setIsError(true);
                showErrorToast(error.message);
            } else {
                setIsPasswordConfirmed(true);
            }

            setIsSubmitting(false);
        },
        [confirmedPassword, isSubmitting, password, showErrorToast]
    );

    return (
        <AuthWrapper>
            <GrandLogoVert width={140} />
            <StandardizedBumper />
            {!isVerified && !isPasswordConfirmed ? (
                <AuthForm onSubmit={onVerifyAccount}>
                    <Fonts.Heading6
                        $color={colors.mountainAsh}
                    >{`Verify`}</Fonts.Heading6>
                    <StandardizedBumper />
                    <Fonts.Medium $textAlign="center">
                        {`Please verify your account by entering the 6 digit code you received in your email`}
                    </Fonts.Medium>
                    <StandardizedBumper />
                    <TextInput
                        type="password"
                        id="verification-code"
                        name="verification-code"
                        onInput={onVerificationCodeInput}
                        label="6-digit code"
                        isError={isError}
                        required
                        $backgroundColor={transparencies.darkJungle5}
                    />
                    <StandardizedBumper />
                    <CheckboxWrapper>
                        <Checkbox
                            type="checkbox"
                            name="share"
                            onChange={onToggleAcceptTerms}
                            checked={hasAcceptedTerms}
                            required
                        />
                        <Fonts.Small>
                            {`By checking this box, I acknowledge that I accept The Grand's`}
                            &nbsp;
                            <Fonts.Small
                                as="a"
                                href="https://www.thegrand.world/termsofservice"
                                target="_blank"
                                rel="noreferrer"
                                $isUnderlined
                            >
                                {`Terms of Service`}
                            </Fonts.Small>
                            &nbsp;{`and`}&nbsp;
                            <Fonts.Small
                                as="a"
                                href="https://www.thegrand.world/privacypolicy"
                                target="_blank"
                                rel="noreferrer"
                                $isUnderlined
                            >
                                {`Privacy Policy`}
                            </Fonts.Small>
                            <RequiredAsterisk />
                        </Fonts.Small>
                    </CheckboxWrapper>
                    <StandardizedBumper />
                    <Button
                        type="submit"
                        value="Submit"
                        isLoading={isSubmitting}
                        $stretch
                    >
                        {`Verify`}
                    </Button>
                    <StandardizedBumper />
                    <Link to="/v3/password-reset">
                        <Fonts.Small
                            $isUnderlined
                        >{`Invalid code? Get a new code here`}</Fonts.Small>
                    </Link>
                </AuthForm>
            ) : null}

            {isVerified && !isPasswordConfirmed ? (
                <AuthForm onSubmit={onSetPassword}>
                    <Fonts.Heading6
                        $color={colors.mountainAsh}
                        $textAlign="center"
                    >{`Welcome, ${
                        userProfile?.full_name?.split(' ')[0] ??
                        'Grand Explorer'
                    }!`}</Fonts.Heading6>
                    <StandardizedBumper />
                    <TextInput
                        type="password"
                        id="password"
                        name="password"
                        onInput={onPasswordInput}
                        label="Password"
                        isError={isError}
                        required
                        $backgroundColor={transparencies.darkJungle5}
                    />
                    <StandardizedBumper />
                    <TextInput
                        type="password"
                        id="confirm-password"
                        name="confirm-password"
                        onInput={onConfirmPasswordInput}
                        label="Confirm password"
                        isError={isError}
                        required
                        $backgroundColor={transparencies.darkJungle5}
                    />
                    <StandardizedBumper />
                    <Button
                        type="submit"
                        value="Submit"
                        isLoading={isSubmitting}
                        $stretch
                    >
                        {`Set password`}
                    </Button>
                </AuthForm>
            ) : null}

            {isVerified && isPasswordConfirmed ? (
                <StepsCompleteTextWrapper>
                    <Fonts.Heading6 $textAlign="center">{`Your password has been updated!`}</Fonts.Heading6>
                    <StandardizedBumper />
                    <Link to="/v3/member">
                        <Button>{`Continue`}</Button>
                    </Link>
                    <StandardizedBumper />
                    <StandardizedBumper />
                    <StandardizedBumper />
                    <StandardizedBumper />
                    <StandardizedBumper />
                    <StandardizedBumper />
                    <StandardizedBumper />
                    <StandardizedBumper />
                    <StandardizedBumper />
                </StepsCompleteTextWrapper>
            ) : null}
        </AuthWrapper>
    );
};
