import React, { useCallback, useMemo } from 'react';

import { useMemberGroupData } from 'v3/hooks/useMemberGroupData';
import { useNavigateWithParams } from 'v3/hooks/useNavigateWithParams';

import { getRelationshipToToday } from 'v3/libs/utils';

import { colors, Fonts, spacing } from 'v3/styles/theme';
import { Card, ContentScroller, StandardizedBumper } from 'v3/styles/shared';
import { BannerWrapper, SessionListWrapper } from 'v3/styles/sessionList';
import { SessionCard } from '../ui/SessionCard';

export const SessionList = () => {
    const { group, sessionDetails } = useMemberGroupData();
    const navigateWithParams = useNavigateWithParams();

    const nextSessionRelationshipToToday = useMemo(
        () =>
            group?.next_session_date == null
                ? 'upcoming'
                : getRelationshipToToday(new Date(group?.next_session_date)),
        [group?.next_session_date]
    );

    const checkSessionRelationshipToToday = useCallback(
        (sessionNumber: number) => {
            if (sessionNumber < (group?.session_counter ?? 0)) {
                return 'previous';
            } else if (sessionNumber > (group?.session_counter ?? 0)) {
                return 'upcoming';
            } else if (sessionNumber === (group?.session_counter ?? 0)) {
                return nextSessionRelationshipToToday;
            } else {
                return 'upcoming';
            }
        },
        [group?.session_counter, nextSessionRelationshipToToday]
    );

    const onSelectSession = useCallback(
        (sessionNumber: number) => () => {
            navigateWithParams(
                `/v3/member/all-sessions/${sessionNumber}/description`
            );
        },
        [navigateWithParams]
    );

    return (
        <ContentScroller>
            <StandardizedBumper $minHeight={spacing.xxxLarge} />
            <StandardizedBumper $minHeight={spacing.xxxLarge} />
            <Card>
                <BannerWrapper>
                    <Fonts.Heading5>{`All Sessions`}</Fonts.Heading5>
                    <StandardizedBumper />
                    <Fonts.Heading7 $color={colors.mountainAsh}>
                        {group?.gid}
                    </Fonts.Heading7>
                </BannerWrapper>
                <SessionListWrapper>
                    {/* Type check is working in development, but compiler flags on build
                    eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    @ts-ignore */}
                    {sessionDetails.map((sessionDetailsDatum, index) => (
                        <SessionCard
                            key={sessionDetailsDatum?.id}
                            number={index + 1}
                            title={sessionDetailsDatum?.title ?? ''}
                            sessionRelationshipToToday={checkSessionRelationshipToToday(
                                index + 1
                            )}
                            onClick={onSelectSession(index + 1)}
                        />
                    ))}
                </SessionListWrapper>
            </Card>
        </ContentScroller>
    );
};
